import interact from 'interactjs';
import Connector from '../../../lib/plateMap/connector.js';


export default class MappingTool {
  constructor(eventManager) {
    this.eventManager = eventManager;
    this.iconCssClass = 'fa-random';
    this.tooltip = 'Map plate contents into new plates';
    this.name = 'plateMap';
    this._selected = false;
  }

  select() {
    this._selected = true;
    this.eventManager.trigger('plate/mapping', {});
  }

  deselect() {
    this._selected = false;
  }

  isSelected() {
    return this._selected;
  }

  toJson() {
    return {
      iconCssClass: this.iconCssClass,
      name: this.name,
      tooltip: this.tooltip,
      selected: this.isSelected()
    };
  }

  activate(view) {
    // Add connectors circles (the things you can click on to connect plates)
    view.plateSetViews.forEach((plateSetView) => {
      plateSetView.root.append(`<div class='mappingConnector right'></div>`);
      plateSetView.root.append(`<div class='mappingConnector left'></div>`);
      plateSetView.root.append(`<div class='mappingConnector top'></div>`);
      plateSetView.root.append(`<div class='mappingConnector bottom'></div>`);
    });
    const dropzone = interact('.mappingConnector').dropzone({
      ondrop: (event) => {
        const sourceCircle = jQuery(event.relatedTarget);
        const destCircle = jQuery(event.target);

        const oldConnector = sourceCircle.data('connector');
        if (oldConnector) {
          oldConnector.destroy();
        }

        let startDirection = 'horizontal';
        let endDirection = 'horizontal';
        if (sourceCircle.hasClass('top') || sourceCircle.hasClass('bottom')) {
          startDirection = 'vertical';
        }
        if (destCircle.hasClass('top') || destCircle.hasClass('bottom')) {
          endDirection = 'vertical';
        }

        const connector = new Connector({
          start: {
            x: sourceCircle.offset().left - view.root.offset().left + 5,
            y: sourceCircle.offset().top - view.root.offset().top + 6,
            direction: startDirection
          },
          end: {
            x: destCircle.offset().left - view.root.offset().left + 7,
            y: destCircle.offset().top - view.root.offset().top + 9,
            direction: endDirection
          },
          scope: view.root,
          color: 'yellow'
        });

        const elt = connector.render();
        view.root.append(elt);

        const sourcePlateSetId = sourceCircle.closest('.plateSet').data('id');
        const destPlateSetId = destCircle.closest('.plateSet').data('id');
        console.log('source pid', sourcePlateSetId);
        console.log('dest plate id', destPlateSetId);
        this.eventManager.trigger('plateMap/update', {
          sourcePlateSetId: sourcePlateSetId,
          destinationPlateSetId: destPlateSetId
        });
      }
    });

    dropzone.on('dropactivate', (event) => {
      event.target.classList.add('drop-activated');
    });
    dropzone.on('dragenter', (event) => {
      event.target.classList.add('dropenter');
    });
    dropzone.on('dragleave', (event) => {
      event.target.classList.remove('dropenter');
    });

    interact('.mappingConnector').draggable({
      inertia: false,
      // allowFrom: '.connector',
      autoScroll: true,
      listeners: {
        start(event) {
          const sourceCircle = jQuery(event.target);

          let startDirection = 'horizontal';
          const endDirection = 'horizontal';
          if (sourceCircle.hasClass('top') || sourceCircle.hasClass('bottom')) {
            startDirection = 'vertical';
          }
          console.log('started mapping drag');
          const connector = new Connector({
            start: {
              x: sourceCircle.offset().left - view.root.offset().left + 11,
              y: sourceCircle.offset().top - view.root.offset().top + 6,
              direction: startDirection
            },
            end: {
              x: event.clientX0 - view.root.offset().left,
              y: event.clientY0 - view.root.offset().top - 2,
              direction: endDirection
            },
            scope: view.root
          });
          sourceCircle.data('connector', connector);
          const elt = connector.render();
          view.root.append(elt);
        },
        move(event) {
          const target = jQuery(event.target);

          let arrowDirection = 'horizontal';
          if (Math.abs(event.dy) > Math.abs(event.dx)) {
            // Switch to downward facing arrow
            arrowDirection = 'vertical';
          }

          const connector = target.data('connector');
          connector.moveEnd({
            x: event.client.x - view.root.offset().left + window.scrollX,
            y: event.client.y - view.root.offset().top + window.scrollY,
            direction: arrowDirection
          });
        },
        end(event) {
          const target = jQuery(event.target);
          console.log(event);
          const connector = target.data('connector');
          if (connector) {
            connector.destroy();
          }
        }
      }
    });
  }

  deactivate(view) {
    interact('.mappingConnector').draggable(false);
    interact('.mappingConnector').dropzone(false);
    jQuery('.mappingConnector').remove();
    jQuery('.myArrowClass').remove();
    this.eventManager.trigger('plateMapTool/closeWindow', {});
  }
}
