// A Movement Definition is a set of attributes which define how to move or iterate
// through a grid. It's a simple data object where its main goal is to collect all the attributes
// together. It doesn't have any complex logic or associations. It's just a way to transport
// these movement attributes.
// For example MovementDefinition can have stopRow and stopCol left undefined,
// in which case the movement will stop at the edge of the container.
// But a MovementRule will always have stopRow and stopCol set to a value.
// See also: MovementRule, which is a little different.
export default class MovementDefinition {
  constructor(attrs = { startRow: null, startCol: null, stopRow: null, stopCol: null, axis: null, wrapAt: null, horizontalDirection: null, verticalDirection: null }) {
    this.startRow = attrs.startRow || 0;
    this.startCol = attrs.startCol || 0;
    this.stopRow = attrs.stopRow; // We won't default to zero, instead we'll use the max size of the grid.
    this.stopCol = attrs.stopCol; // We won't default to zero, instead we'll use the max size of the grid.
    this.axis = attrs.axis || 'row';
    this.wrapAt = parseInt(attrs.wrapAt, 10) || 0;
    this.horizontalDirection = attrs.horizontalDirection || 'right';
    this.verticalDirection = attrs.verticalDirection || 'down';
  }

  get primaryDirection() {
    if (this.axis == 'col') {
      return this.verticalDirection;
    } else if (this.axis == 'row') {
      return this.horizontalDirection;
    } else {
      throw new Error(`this.axis not expected: ${this.axis}`);
    }
  }

  get secondaryDirection() {
    if (this.axis == 'row') {
      return this.verticalDirection;
    } else if (this.axis == 'col') {
      return this.horizontalDirection;
    } else {
      throw new Error(`this.axis not expected: ${this.axis}`);
    }
  }

  get state() {
    return {
      startRow: this.startRow,
      startCol: this.startCol,
      stopRow: this.stopRow,
      stopCol: this.stopCol,
      axis: this.axis,
      wrapAt: this.wrapAt,
      horizontalDirection: this.horizontalDirection,
      verticalDirection: this.verticalDirection
    };
  }

  // Make a copy of this movement definition, and change any attributes
  // that are given as parameters
  copy(changeAttrs = {}) {
    const newAttrs = {};
    Object.assign(newAttrs, this.state, changeAttrs);
    return new MovementDefinition(newAttrs);
  }

  
}
