// This is the window that you see when you choose "File > Open..."
export default class OpenPlatesView {
  constructor(scope, state, eventManager) {
    this.scope = scope;
    this._state = state;
    this.eventManager = eventManager;
    this.root = jQuery(`<div class='openPlatesModal window'></div>`);
  }

  setState(newState) {
    this._state = newState;
    this.render();
  }

  get(attr) {
    return this._state[attr];
  }

  render() {
    this.root.empty();

    if (this.get('visible')) {
      this.root.show();
    } else {
      this.root.hide();
    }

    this.root.append(jQuery(`<div class='header'>Open Plates</div>`));
    if (this.get('options')) {
      const optionsContainer = jQuery(`<div class='options'></div>`);
      const options = this.get('options');
      options.forEach((option) => {
        const plate = option.plate;
        const element = jQuery(`<div class='option' data-option='${plate.id}'>Plate ${plate.name}, ${plate.id}</div>`);
        optionsContainer.append(element);
      });
      this.root.append(optionsContainer);
    } else {
      this.root.append(jQuery(`<div class='options'>Loading...</div>`));
    }

    this.root.append(jQuery(`
      <div class='footer'><button data-close>Cancel</button></div>
    `));
    return this.root;
  }

  getPlateData(plateId) {
    let plateData = null;
    this.get('options').forEach((option) => {
      const plate = option.plate;
      if (plate.id == plateId) {
        plateData = plate;
      }
    });

    return plateData;
  }

  initEvents() {
    this.scope.on('click', '[data-option]', (event) => {
      const element = jQuery(event.target);
      const plateId = element.attr('data-option');
      const plateData = this.getPlateData(plateId);
      this.eventManager.trigger('plateSet/add', { plate: plateData });
      this.eventManager.trigger('openPlates/closeWindow', {});
    });
    this.scope.on('click', '[data-close]', (event) => {
      this.eventManager.trigger('openPlates/closeWindow', {});
    });
  }
}
