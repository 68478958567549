import ArrowTool from './toolbar/tools/arrowTool';
import SelectTool from './toolbar/tools/selectTool';
import PointerTool from './toolbar/tools/pointerTool';
import ZoomTool from './toolbar/tools/zoomTool';
// import Tool from './toolbar/tool';
import MappingTool from './toolbar/tools/mappingTool';

export default class Toolbar {
  constructor(eventManager, state = null) {
    this.eventManager = eventManager;
    this._state = state || Toolbar.defaultState();

    this.tools = [
      new ArrowTool(this.eventManager),
      new SelectTool(this.eventManager),
      new PointerTool(this.eventManager),
      new ZoomTool(this.eventManager),
      new MappingTool(this.eventManager)
    ];

    this._state.selectedTool = null;
  }

  static defaultState() {
    return {
      tools: [],
      selectedTool: null
    };
  }

  getTool(name) {
    return this.tools.find((tool) => (tool.name === name));
  }

  get state() {
    this._state.tools = [];
    this.tools.forEach((tool) => {
      this._state.tools.push(tool.toJson());
    });

    return this._state;
  }

  selectTool(toolName) {
    console.log('selectTool: toolname', toolName);
    this._state.selectedTool = toolName;
    let selectedTool = null;

    this.tools.forEach((tool) => {
      if (tool.name === toolName) {
        tool.select();
        selectedTool = tool;
        console.log('selectedtool2', selectedTool);
      } else {
        tool.deselect();
      }
    });

    return selectedTool;
  }

  get selectedTool() {
    return this.getTool(this._state.selectedTool);
  }
}
