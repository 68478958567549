import Dropzone from 'dropzone';
import BoxSelection from '../../lib/boxSelection';
import PlateSetView from '../plate/plateSetView';
import interact from 'interactjs';
import ColorService from '../../lib/colorService';

export default class PlatePanelView {
  constructor(scope, state, eventManager) {
    this.scope = scope;
    this._state = state;
    this.eventManager = eventManager;
    this._plateSetViews = {};
    this.root = jQuery(`<div class='platePanel dropzone'></div>`);
    this.dropzone = null;
    this.dropzoneTarget = null; // This is a bit of a hack;
    this.dragselect = null;

    // The color service is shared across all platesets so they can render
    // equivalent items in the same color
    this.colorService = new ColorService({ numColors: 20, onlyDarkColors: true });
  }

  appendPlate(plateSetView) {
    this._plateSetViews[plateSetView.plateSet.id()] = plateSetView;
    this.root.append(plateSetView.render());
    this.selectPlateSet(plateSetView.plateSet);
  }

  setState(newState) {
    this._state = newState;
    this.render();
  }

  get state() {
    return this._state;
  }

  get(attr) {
    return this._state[attr];
  }

  selectedPlateSet() {
    return this.plateSets.find((plateSet) => {
      return plateSet.isSelected();
    });
  }

  firstSelectedPlateOrFirstPlate() {
    const plateSet = this.selectedPlateSet();
    const firstSelectedPlate = plateSet.plates.find((plate) => {
      return plate.isSelected();
    });

    return firstSelectedPlate || plateSet.getPlate(0);
  }

  get plateSetViews() {
    return Object.values(this._plateSetViews);
  }

  get plateSets() {
    return this.plateSetViews.map((psv) => {
      return psv.plateSet;
    });
  }

  getPlateSetView(plateId) {
    let psv = null;
    psv = this.plateSetViews.find((psv) => {
      return psv.hasPlateId(plateId);
    });
    console.log('psv', psv);
    return psv;
  }

  getPlate(plateId) {
    const plateSetView = this.getPlateSetView(plateId);
    console.log('plateSetView', plateSetView);
    return plateSetView.getPlate(plateId);
  }

  getPlateSet(plateSetId) {
    return this.plateSets.find((ps) => ps.id() == plateSetId );
  }

  render() {
    console.log('=== Rendering plate sets ===');

    this.get('plateSets').forEach((plateSet) => { // <<<
      let plateSetView = this._plateSetViews[plateSet.id()];
      if (plateSetView != null) {
        plateSetView.render();
      } else {
        plateSetView = new PlateSetView(this.scope, plateSet, this.eventManager, { colorService: this.colorService });
        this._plateSetViews[plateSet.id()] = plateSetView;
        this.root.append(plateSetView.render());
      }

      setTimeout(() => {
        plateSetView.initEvents();
      }, 500);
    });

    this.root.append(jQuery(`<div id='login' class='login'></div>`));

    return this.root;
  }

  initEvents() {
    // Init dropzone for file drag & drop uploading
    if (!this.dropzone && jQuery('.dropzone').length > 0) {
      this.root.append(jQuery(`<div class="dz-message" data-dz-message><span></span></div>)`)); // Hide Dropzone's default message
      this.root.append(jQuery(`<i class="fa-solid fa-upload fa-8x uploadIcon"></i>`));
      this.initDropzone();
    }

    // Init events on all children (plateViews)
    Object.values(this.plateSetViews).forEach((plateSetView) => {
      plateSetView.initEvents();
    });

    // Key listeners
    jQuery(document).on('keyup', (event) => {
      const DELETE_KEY = 8;

      if (event.which == DELETE_KEY) {
        this.eventManager.trigger('selectedItems/delete', {});
      }
    });

    this.initCopyAndPaste();
    this.initDragSelect();
    this.initDraggable();
  }

  initDragSelect() {
    if (this.dragselect == null) {
      this.dragselect = new BoxSelection({
        container: this.root.get(0), // The scope in which BoxSelection should function.
        itemSelector: '.well', // The CSS class BoxSelection will use to target items.
        selectedClass: '.selecting', // The CSS class that will be added to the HTML elements that fall within the selection box.
        selectionClass: '.selection', // The CSS class that will be added to the selection box.
        mode: 'loose', // 'loose' or 'strict'
        onSelectionChanging: (selectedItems, event) => {
          console.log('selected', selectedItems); // Array of HTML elements that fall within the selection box.
        },
        onSelectionChanged: (selectedItems, event) => {
          const wellDivs = selectedItems.map((div) => jQuery(div));
          const wellIds = wellDivs.map((wellDiv) => wellDiv.data('id'));
          const plateSetIds = wellDivs.map((wellDiv) => wellDiv.closest('.plateSet').data('id'));

          // Just select items in the first plateSet that they dragged over
          const plateSetView = this.plateSetViews.find((psv) => plateSetIds.includes(psv.id()));
          if (plateSetView != null) {
            this.eventManager.trigger('plateSet/selectWells', {
              plateSet: plateSetView.plateSet,
              wellIds: wellIds
            });
          }
        }
      });

      // Start with it turned off. Then we'll enable it when the user chooses
      // the selection tool.
      this.dragselect.disable();
    }
  }

  // Ajax request to the server to save all the data.
  save() {
    console.log('saving', this.toJson());
    jQuery.post({
      url: '/plates/save',
      dataType: 'json',
      data: this.toJson(),
      success: (event) => console.log('save: success!', event),
      fail: (event) => console.log('save: failure', event)
    });
  }

  initCopyAndPaste() {
    document.addEventListener('copy', (event) => {
      event.preventDefault();

      const plateSet = this.selectedPlateSet();
      this.eventManager.trigger('plateSet/copy', {
        plateSet: plateSet,
        clipboardData: event.clipboardData
      });
    });

    document.addEventListener('paste', (event) => {
      event.preventDefault();

      const plate = this.firstSelectedPlateOrFirstPlate();
      this.eventManager.trigger('plate/paste', {
        plate: plate,
        clipboardData: event.clipboardData
      });
    });

    document.addEventListener('cut', (event) => {
      event.preventDefault();
      // event.clipboardData.setData('text/plain', 'text to clipboard');
    });
  }

  initDropzone() {
    this.dropzone = new Dropzone('.dropzone', {
      autoProcessQueue: false,
      url: 'dev.null',
      createImageThumbnails: false,
      clickable: false,
      disablePreviews: true,
      drop: (e) => {
        this.dropzoneTarget = jQuery(e.target).closest('.plate');
        return this.root.removeClass('dz-drag-hover');
      },
      accept: (file, done) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          if (this.dropzoneTarget.length == 0) {
            return;
          }

          const plateId = this.dropzoneTarget.data('id');
          const plateSetId = this.dropzoneTarget.closest('.plateSet').data('id');
          const plate = this.getPlate(plateId);
          const plateSet = this.getPlateSet(plateSetId);
          this.eventManager.trigger('platePanel/fileUploaded', {
            plateSet: plateSet,
            plate: plate,
            file: file,
            fileContents: reader.result
          });
        };

        // This line triggers the events that are handled above
        reader.readAsText(file);
      }
    });
  }

  initDraggable() {
    interact('.draggable').draggable({
      inertia: false,
      allowFrom: '.header',
      autoScroll: true,
      listeners: {
        start(event) {
          const target = jQuery(event.target);
          target.css({'z-index': 1001}); // Should probably just select() the plate at this time...
        },
        move(event) {
          const target = jQuery(event.target);
          const newLeft = target.position().left + event.dx;
          const newTop = target.position().top + event.dy;
          target.css({position: 'absolute', top: newTop, left: newLeft});
        },
        end(event) {
          const target = jQuery(event.target);
          target.css({'z-index': ''});
        }
      }
    });

    interact('.resizable').resizable({
      edges: { top: false, left: true, bottom: false, right: true },
      preserveAspectRatio: true,
      listeners: {
        move: function(event) {
          let { x, y } = event.target.dataset;

          x = (parseFloat(x) || 0) + event.deltaRect.left;
          y = (parseFloat(y) || 0) + event.deltaRect.top;

          Object.assign(event.target.style, {
            width: `${event.rect.width}px`,
            // height: `${event.rect.height}px`,
            transform: `translate(${x}px, ${y}px)`
          });

          Object.assign(event.target.dataset, { x, y });
        },
        end: (event) => {
          // eslint-disable-next-line no-unused-vars
          let { x, y } = event.target.dataset;

          x = (parseFloat(x) || 0) + event.deltaRect.left;
          // eslint-disable-next-line no-unused-vars
          y = (parseFloat(y) || 0) + event.deltaRect.top;

          const plateSetId = jQuery(event.target).closest('.plateSet').data('id');

          console.log('plateSetId', plateSetId);
          this.plateSetViews.forEach((psv) => console.log('psv.id()', psv.id()));
          // Just select items in the first plate that they dragged over
          const plateSetView = this.plateSetViews.find((psv) => plateSetId == psv.id());

          this.eventManager.trigger('plateSet/resize', {
            plateSet: plateSetView,
            width: event.rect.width + x
            // height: event.rect.height + y
          });
        }
      }
    });

    interact('.draggable').draggable(false);
    interact('.resizable').resizable(false);
  }
}
