export default class Alphabet {
  static get letters() {
    return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  }

  static get(index) {
    return this.letters[index % this.letters.length];
  }

  static slice(x, y) {
    return this.letters.slice(x, y);
  }
}
