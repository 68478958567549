import interact from 'interactjs';

export default class PointerTool {
  constructor(eventManager) {
    this.eventManager = eventManager;
    this.iconCssClass = 'fa-hand-pointer';
    this.name = 'pointer';
    this.tooltip = 'Point at things';
    this._selected = false;
  }

  select() {
    this._selected = true;
  }

  deselect() {
    this._selected = false;
  }

  isSelected() {
    return this._selected;
  }

  toJson() {
    return {
      iconCssClass: this.iconCssClass,
      name: this.name,
      tooltip: this.tooltip,
      selected: this.isSelected()
    };
  }

  activate() {
    interact('.draggable').draggable(true);
    interact('.resizable').resizable(true);
  }

  deactivate() {
    interact('.draggable').draggable(false);
    interact('.resizable').resizable(false);
  }
}
