export default class SelectTool {
  constructor(eventManager) {
    this.eventManager = eventManager;
    this.iconCssClass = 'fa-object-group';
    this.tooltip = 'Rectangular selection';
    this.name = 'select';
    this._selected = false;
  }

  select() {
    this._selected = true;
  }

  deselect() {
    this._selected = false;
  }

  isSelected() {
    return this._selected;
  }

  toJson() {
    return {
      iconCssClass: this.iconCssClass,
      name: this.name,
      tooltip: this.tooltip,
      selected: this.isSelected()
    };
  }

  activate(view) {
    console.log('enable drag select');
    view.dragselect.enable();
  }

  deactivate(view) {
    console.log('disable drag select');
    view.dragselect.disable();
  }
}
