import Range from '../../lib/range.js';
import ColorService from '../../lib/colorService.js';
// import PlateSet from '../../lib/plate/plateSet.js';

// NOTE: I just changed this from "PlateView" to "PlateSetView"
// and lots of things are not updated to reflect that yet.
export default class PlateSetView {
  constructor(scope, plateSet, eventManager, options = { colorService: null }) {
    this.scope = scope;
    this.eventManager = eventManager;

    this.plateSet = plateSet; // new PlateSet({plates: [plate, plate]});
    console.log('new PlateSETView, plateSet: ', this.plateSet);
    this.root = jQuery(`
      <div class='plateSet draggable resizable selectable'>
        <div class='header'>
          <div class='left'>
            <p>
              <i class='fa-solid fa-circle-check'></i>
              PlateSet ${this.plateSet.onScreenName()}
            </p>
          </div>
          <div class='right'>
            <button type="button" class='button-4' data-newPlateButton><i class="fa-regular fa-circle-plus"></i>&nbsp;Add Plate</button>
            <i class="fa-regular fa-circle-xmark close-icon" data-plateCloseButton></i>
          </div>
        </div>
        <div class='body'></div>
      </div>
    `);
    this.root.data('id', this.id());
    this.root.data('type', 'plateSet'); // This is used when the item is selected to know what kind of item was selected

    this.body = this.root.find('.body');
    this.colorService = options.colorService || new ColorService({ numColors: 96, onlyDarkColors: false });
    this.colorService2 = options.colorService || new ColorService({ numColors: 20, onlyDarkColors: true });

    this._eventsInitialized = false;
  }

  render() {
    this.body.empty();
    this.root.width(this.plateSet.getState('width'));
    this.plateSet.plates.forEach((plate) => {
      const plateElement = this.renderPlate(plate);
      this.body.append(plateElement);

      this.root.width(plate.getState('width'));
      plateElement.css('aspect-ratio', `${plate.numCols()}/${plate.numRows()}`);
      // this.root.height(plate.getState('height'));
    });

    if (this.plateSet.isSelected()) {
      this.root.addClass('selected');
    } else {
      this.root.removeClass('selected');
    }

    return this.root;
  }

  renderPlate(plate) {
    const plateElement = jQuery(`<div class='plate selectable'></div>`);
    plateElement.data('id', plate.id());
    plateElement.data('type', 'plate'); // This is used when the item is selected to know what kind of item was selected
    if (plate.isSelected()) {
      plateElement.addClass('selected');
    } else {
      plateElement.removeClass('selected');
    }

    plateElement.append(jQuery(`<div class='header'><i class='fa-solid fa-circle-check'></i>&nbsp;${plate.onScreenName()}</div>`));
    // plateElement.width(plate.numCols() * 37.5);
    // plateElement.height(plate.numRows() * 37.5);

    const renderedLayout = this.renderLayout(plate, plate.layout);
    plateElement.append(renderedLayout);

    plate.visibleLayers().forEach((layer) => {
      console.log('rendering layer', layer.state);
      const renderedLayer = this.renderLayer(plate, layer);
      plateElement.append(renderedLayer);
    });

    // plateElement.width(plate.getState('width'));
    // plateElement.height(plate.getState('height'));

    return plateElement;
  }

  // Layout is just a layer with a little different appearance because
  // it has the class 'layout' added to the div.
  renderLayout(plate, layout) {
    const layer = layout.layer;
    return this.renderLayer(plate, layer, 'layout');
  }

  renderLayer(plate, layer, extraClasses) {
    const rowDivs = [];
    const wellMatrix = layer.wellMatrix();
    const wellRangeCache = this.calculateWellRanges(wellMatrix);
    wellMatrix.forEach((row) => {
      const rowDiv = this.renderRow(plate, row, wellRangeCache);
      rowDivs.push(rowDiv);
    });

    const layerElement = jQuery(`<div class='layer ${extraClasses}'></div>`);
    layerElement.append(rowDivs);

    return layerElement;
  }

  renderRow(plate, row, wellRangeCache) {
    const cells = [];
    row.forEach((well) => {
      const cssClasses= ['well'];
      if (well.isSelected()) cssClasses.push('selected');

      const wellDiv = jQuery(`
        <div class='${cssClasses.join(' ')}' data-tooltip="(${well.coordinateString()}) ${well.name()}" style="background-color: ${this.wellColor(plate, well, wellRangeCache)};">
          <div class='label'>${well.label()}</div>
        </div>
      `);
      wellDiv.data('id', well.id());
      cells.push(wellDiv);
    });

    const rowDiv = jQuery(`<div class='row'></div>`);
    rowDiv.append(cells);

    return rowDiv;
  }

  initEvents() {
    if (this._eventsInitialized) {
      return;
    }

    document.addEventListener('dragover', function(event) {
      event.preventDefault();
    });

    this.initNewPlateButton();

    this._eventsInitialized = true;
  }

  initNewPlateButton() {
    this.root.on('click', '[data-newPlateButton]', (event) => {
      const data = {
        plate: {
          numRows: this.plateSet.numRows,
          numCols: this.plateSet.numCols,
          plateSetId: this.plateSet.id(),
          layoutId: this.plateSet.layoutId
        }
      };
      this.eventManager.trigger('plate/add', data);
    });
  }

  layerSelectedHandler(eventType, data) {
    if (data.plate.id() != this.plate.id()) {
      return; // It's not our plate that changed.
    }

    this.eventManager.trigger('platePanel/layerSelected', { plate: this.plate, layerId: data.layerId });
  }

  select() {
    if (this.plate.isSelected()) {
      return false;
    }
    // this.root.addClass('selected');
    // this.plate.select();
    this.eventManager.trigger(this, 'platePanel/plateSelected', {plate: this.plate});
  }

  deselect() {
    this.root.removeClass('selected');
    this.plateSet.deselect();
  }

  // This is the old way of doing it. Please update to use this.eventManager
  eventListener(eventType, data) {
    if (eventType == 'plateChanged') {
      this.plate = data.plate;
      this.rerender(data);
    }
  }

  id() {
    return this.plateSet.id();
  }

  hasPlateId(plateId) {
    return this.plateSet.hasPlateId(plateId);
  }

  getPlate(plateId) {
    return this.plateSet.getPlateById(plateId);
  }

  plateChangedHandler(eventType, data) {
    if (data.plate.id() != this.plate.id()) {
      return; // It's not our plate that changed.
    }
    this.render();
  }

  wellColor(plate, well, wellRangeCache) {
    const colorDisplay = plate.getState('colorDisplay');
    let color = 'rgba(0, 0, 0, 0)'; // Default color
    if (colorDisplay == 'content') {
      color = this.colorService.getColor(well.label());
    } else if (colorDisplay == 'amount') {
      const range = wellRangeCache[well.label()];
      color = this.colorService2.getColorOnScale(well.label(), well.amount(), range);
    } else {
      console.log(`Whoops! Unexpected value for colorDisplay '${colorDisplay}'`);
    }
    return color;
  }

  deselectWells() {
    this.plate.deselectWells();
  }

  selectWells(wellIds) {
    // this.plate.selectWells(wellIds);
    this.eventManager.trigger('platePanel/wellsSelected', { plate: this.plate, wellIds: wellIds });
  }

  // For each unique type of content, `well.label()`,
  // find the min and max volumeAmount
  // Note that this assumes all wells in the matrix have the same volumeUnits
  calculateWellRanges(wellMatrix) {
    const types = {};
    wellMatrix.forEach((row) => {
      row.forEach((well) => {
        const label = well.label();
        const range = types[label] || new Range();
        range.expandRange(well.amount());
        types[label] = range;
      });
    });

    return types;
  }
}
