import template from './plateMapping.html';
import MovementDefinition from '../../lib/movementDefinition';
import RepeatDefinition from '../../lib/plateMap/repeatDefinition';
import { newId } from '../../lib/newId';

export default class PlateMappingView {
  constructor(scope, state, eventManager) {
    this.scope = scope;
    this._state = state;
    this._state.id = newId();
    this.eventManager = eventManager;
    this.root = jQuery(template);
  }

  setState(newState) {
    this._state = newState;
    this.render();
  }

  get(attr) {
    return this._state[attr];
  }

  apply() {
    // Finalize the mapping
    // Reset/remove the mapping id
    // Close/hide the window
    // Remove the arrows...
    throw new Error('Not implemented');
  }

  render() {
    // this.root.empty();
    this.sourceSection = this.root.find('[data-section="source"]');
    this.destSection = this.root.find('[data-section="destination"]');
    this.repeatDefinitionSection = this.root.find('[data-section="repeatDefinition"]');

    if (this.get('visible')) {
      this.root.show();
    } else {
      this.root.hide();
    }
    console.log('plateMappingView render state', this._state);

    if (this.get('plateSets')) {
      const sourcePlateOptions = this.sourceSection.find('select[name="plateSet"]');
      const destPlateOptions = this.destSection.find('select[name="plateSet"]');
      const plateSets = this.get('plateSets');
      plateSets.forEach((plateSet) => {
        const sourceOption = jQuery(`<option value='${plateSet.id()}'>${plateSet.onScreenName()}</option>`);
        sourcePlateOptions.append(sourceOption);

        const destOption = jQuery(`<option value='${plateSet.id()}'>${plateSet.onScreenName()}</option>`);
        destPlateOptions.append(destOption);
      });
    }

    return this.root;
  }

  getPlateSet(plateSetId) {
    let plateSet = null;
    this.get('plateSets').forEach((ps) => {
      if (ps.id == plateSetId) {
        plateSet = ps;
      }
    });

    return plateSet;
  }

  initEvents() {
    this.scope.on('change', (event) => {
      this.runMapping();
    });

    this.scope.on('click', '[data-close]', (event) => {
      this.eventManager.trigger('plateMapTool/closeWindow', {});
    });
  }

  runMapping() {
    const source = this.root.find('[data-section="source"]');
    const dest = this.root.find('[data-section="destination"]');

    const fields = {
      source: {
        plateSetId: source.find('select[name="plateSet"]'),
        axis: source.find('select[name="axis"]'),
        wrapAt: source.find('input[name="wrapAt"]'),
        horizontalDirection: source.find('select[name="horizontalDirection"]'),
        verticalDirection: source.find('select[name="verticalDirection"]')
      },
      destination: {
        plateSetId: dest.find('select[name="plateSet"]'),
        axis: dest.find('select[name="axis"]'),
        wrapAt: dest.find('input[name="wrapAt"]'),
        horizontalDirection: dest.find('select[name="horizontalDirection"]'),
        verticalDirection: dest.find('select[name="verticalDirection"]')
      },
      repeat: {
        count: this.root.find('input[name="repeatCount"]'),
        skipCount: this.root.find('input[name="repeatSkip"]')
      }
    };

    const sourcePlateSetId = fields.source.plateSetId.val();
    const destinationPlateSetId = fields.destination.plateSetId.val();

    if ((sourcePlateSetId == null) || (destinationPlateSetId == null)) {
      console.log('warning: tried to run mapping but source or destination plate is not set', sourcePlateSetId, destinationPlateSetId);
      return false;
    }

    if (sourcePlateSetId == destinationPlateSetId) {
      console.log('warning: tried to run mapping but source and destination plate are the same', sourcePlateSetId, destinationPlateSetId);
      return false;
    }

    const sourceMovement = new MovementDefinition({
      startRow: null,
      startCol: null,
      axis: fields.source.axis.val(),
      wrapAt: parseInt(fields.source.wrapAt.val(), 10),
      horizontalDirection: fields.source.horizontalDirection.val(),
      verticalDirection: fields.source.verticalDirection.val()
    });

    const destinationMovement = new MovementDefinition({
      startRow: null,
      startCol: null,
      axis: fields.destination.axis.val(),
      wrapAt: parseInt(fields.destination.wrapAt.val(), 10),
      horizontalDirection: fields.destination.horizontalDirection.val(),
      verticalDirection: fields.destination.verticalDirection.val()
    });

    const repeatDefinition = new RepeatDefinition({
      repeat: parseInt(fields.repeat.count.val(), 10),
      skip: parseInt(fields.repeat.skipCount.val(), 10)
    });

    const eventData = {
      mappingId: this.get('id'),
      source: {
        plateSetId: sourcePlateSetId,
        movementDefinition: sourceMovement,
      },
      destination: {
        plateSetId: destinationPlateSetId,
        movementDefinition: destinationMovement,
      },
      repeatDefinition: repeatDefinition
    };

    this.eventManager.trigger('plates/map', eventData);
  }
}
