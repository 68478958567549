import Plate from './lib/plate';
import PlateAPI from './lib/plateApi';
import PlateClipboard from './lib/plateClipboard';
import Authentication from './lib/authentication';
import PlateSet from './lib/plate/plateSet';
// import IteratorFactory from './lib/plateIterators/iteratorFactory';
import ContainerMap from './lib/plateMap/containerMap';
import CsvPlateSetFiller from './lib/csvPlateSetFiller';

export default class appController {
  constructor(platePanelModel, views) {
    this.platePanelModel = platePanelModel;
    this.platePanelView = views.platePanel;
    this.plateInspectorView = views.plateInspector;
    this.openPlatesView = views.openPlates;
    this.plateMappingView = views.plateMapping;
  }

  get routes() {
    return {
      'plateSet/add': this.addPlateSet.bind(this),
      'plate/add': this.addPlate.bind(this),
      'item/select': this.selectItem.bind(this),
      'selectedItems/delete': this.deleteItem.bind(this),
      'plateSet/selectWells': this.selectWells.bind(this),
      'plate/wellChanged': this.plateWellChanged.bind(this),
      'plate/attributeChanged': this.plateAttributeChanged.bind(this),
      'plateSet/copy': this.plateSetCopy.bind(this),
      'plate/paste': this.platePaste.bind(this),
      'plate/resize': this.plateResize.bind(this),
      'plateSet/zoom': this.plateSetZoom.bind(this),
      'plate/mapping': this.plateMapping.bind(this),
      'plateMapTool/closeWindow': this.closePlateMapTool.bind(this),
      'plates/map': this.mapPlates.bind(this),
      'plateMap/update': this.mapPlatesUpdate.bind(this),
      'platePanel/fileUploaded': this.fileUploaded.bind(this),
      'app/open': this.openDialog.bind(this),
      'app/save': this.save.bind(this),
      'app/copy': this.copy.bind(this),
      'app/paste': this.paste.bind(this),
      'app/login': this.login.bind(this),
      'app/logout': this.logout.bind(this),
      'openPlates/closeWindow': this.openPlatesCloseWindow.bind(this),
      'layer/selected': this.layerSelected.bind(this),
      'layer/add': this.layerAdd.bind(this),
      'layer/delete': this.layerDelete.bind(this),
      'layer/attributeChanged': this.layerAttributeChanged.bind(this),
      'layer/toggleVisibility': this.layerToggleVisibility.bind(this)
    };
  }

  trigger(eventType, data) {
    if (eventType in this.routes) {
      console.log(`appController.triggering ${eventType} with`, data);
      const action = this.routes[eventType];
      action(data);
    } else {
      console.log(`Warning: No actions are defined for '${eventType}'`);
    }
  }

  updateView() {
    this.platePanelView.setState(this.platePanelModel.state);
    this.plateInspectorView.setState(this.platePanelModel.state);
  }

  addPlateSet(eventType, data) {
    const options = {};
    if (data.layoutId != 'custom') {
      options.layoutId = data.layoutId;
    } else {
      options.newPlateCols = data.numCols;
      options.newPlateRows = data.numRows;
    }
    const plateSet = new PlateSet(options);
    this.platePanelModel.addPlateSet(plateSet);
    this.updateView();
  }

  addPlate(eventType, data) {
    const plate = new Plate(data.plate);
    const plateSet = this.platePanelModel.getPlateSet(data.plate.plateSetId);
    console.log('xx adding plate to', plateSet.id());
    plateSet.addPlate(plate);
    // this.platePanelModel.addPlateSet(plateSet);
    this.updateView();
  }

  selectItem(eventType, data) {
    if (data.type == 'plateSet') {
      const plateSet = this.platePanelModel.getPlateSet(data.id);
      this.platePanelModel.selectPlateSet(plateSet.id());
    } else if (data.type == 'plate') {
      const plate = this.platePanelModel.getPlate(data.id);
      this.platePanelModel.togglePlateSelected(plate.id());
      // Select the plateSet too
      // Would it be better to do this by allowing the event to propogate?
      const plateSet = this.platePanelModel.plateSets.find((plateSet) => {
        return plateSet.plates.includes(plate);
      });
      this.platePanelModel.selectPlateSet(plateSet.id());
    } else {
      throw new Error(`selectItem was called but no valid data.type was given. Please describe the type of item that was selected. data.type is '${data.type}'`);
    }

    this.updateView();
  }

  deleteItem(eventType, data) {
    this.platePanelModel.deleteSelected();
    this.updateView();
  }

  fileUploaded(eventType, data) {
    const plate = data.plate;
    const plateSet = data.plateSet;

    // Select the plateSet that was dropped on
    this.platePanelModel.selectPlateSet(plateSet.id());

    // Remove file extension (e.g. ".csv")
    const nameTokens = data.file.name.split('.');
    nameTokens.pop();
    const name = nameTokens.join('.');

    const layer = plateSet.createLayer(name);
    // plate.fillLayerFromCSV(layer, data.file, data.fileContents);
    CsvPlateSetFiller.fill(plateSet, plate, layer, data.file, data.fileContents);
    this.updateView();
  }

  selectWells(eventType, data) {
    const plateSet = data.plateSet;
    this.platePanelModel.selectPlateSet(plateSet.id());
    plateSet.selectWells(data.wellIds, { deselectOthers: true });
    this.platePanelModel.captureSelected('well', plateSet, data.wellIds);
    this.updateView();
  }

  plateWellChanged(eventType, data) {
    this.platePanelModel.updateWell(data.plateId, data.wellId, data.field, data.value);
    this.updateView();
  }

  plateAttributeChanged(eventType, data) {
    this.platePanelModel.updatePlateAttribute(data.plateId, data.field, data.value);
    this.updateView();
  }

  layerSelected(eventType, data) {
    this.platePanelModel.selectLayer(data.plateId, data.layerId);
    this.updateView();
  }

  layerAdd(eventType, data) {
    this.platePanelModel.addLayer(data.plateId);
    this.updateView();
  }

  layerDelete(eventType, data) {
    this.platePanelModel.deleteLayer(data.plateId, data.layerId);
    this.updateView();
  }

  layerAttributeChanged(eventType, data) {
    this.platePanelModel.updateLayerAttribute(data.plateId, data.layerId, data.field, data.value);
    this.updateView();
  }

  layerToggleVisibility(eventType, data) {
    this.platePanelModel.toggleLayerVisibility(data.plateId, data.layerId);
    this.updateView();
  }

  // Copy via Command+C
  plateSetCopy(eventType, data) {
    const plateSet = data.plateSet;
    const clipboardData = data.clipboardData;
    PlateClipboard.copy1(plateSet, clipboardData);
  }

  // This gets called if you press Command-V
  // It's different from Edit > Paste in the menu
  platePaste(eventType, data) {
    console.log('Paste via Command+V', data);
    const plate = data.plate;
    const clipboardData = data.clipboardData;
    const pasted = PlateClipboard.paste1(plate, clipboardData);
    if (pasted) {
      this.updateView();
    }
  }

  openDialog() {
    const data = {};
    PlateAPI.listPlates(data, {
      success: (results) => {
        this.openPlatesView.setState({
          visible: true,
          options: results.data
        });
      }
    });

    this.openPlatesView.setState({
      visible: true
    });
  }

  openPlatesCloseWindow() {
    this.openPlatesView.setState({
      visible: false
    });
  }

  save() {
    const panelJson = this.platePanelModel.toJson();
    panelJson.forEach((plateJson) => {
      PlateAPI.save(plateJson);
    });
  }

  // Copy via Edit > Copy menu
  // I haven't figured out how to get copy and paste to work in browsers
  // if it's triggered like this, and not with command-C, command-V
  copy() {
    alert('Copy via Edit > Copy menu');
    PlateClipboard.copy2(this.platePanelModel.selectedPlate());
  }

  // This gets called if you choose the menu option Edit > Paste
  // It's different from Command-V
  // I haven't figured out how to get copy and paste to work in browsers
  // if it's triggered like this, and not with command-C, command-V
  paste() {
    alert('Not yet implemented.');
  }

  login() {
    Authentication.login();
  }

  logout() {
    Authentication.logout();
  }

  plateResize(eventType, data) {
    console.log('plateResize', data);
    const plate = data.plate;

    plate.resize({
      height: data.height,
      width: data.width
    });

    this.updateView();
  }

  plateSetZoom(eventType, data) {
    const plateSet = this.platePanelModel.getPlateSet(data.plateSetId);
    plateSet.zoom({
      percent: data.percent
    });

    this.updateView();
  }

  plateMapping(eventType, data) {
    // const sourcePlateSet = this.platePanelModel.getPlateSet(data.plateId);
    const plateSets = this.platePanelModel.plateSets;
    this.plateMappingView.setState({
      visible: true,
      plateSets: plateSets,
      sourcePlateSet: null
    });
  }

  closePlateMapTool(eventType, data) {
    this.plateMappingView.setState({
      visible: false
    });
  }

  mapPlates(eventType, data) {
    const sourcePlateSet = this.platePanelModel.getPlateSet(data.source.plateSetId);
    const destinationPlateSet = this.platePanelModel.getPlateSet(data.destination.plateSetId);
    // const sourcePlateSet = new PlateSet({ plates: sourcePlate });
    // const destinationPlateSet = new PlateSet({ plates: destinationPlate });

    const map = new ContainerMap(
        data.mappingId,
        sourcePlateSet,
        data.source.movementDefinition,
        destinationPlateSet,
        data.destination.movementDefinition,
        data.repeatDefinition
    );
    map.mapPlateSet();

    this.updateView();
  }

  mapPlatesUpdate(eventType, data) {
    jQuery('#mappingSourcePlateSetId').val(data.sourcePlateSetId);
    jQuery('#mappingDestinationPlateSetId').val(data.destinationPlateSetId);
    this.plateMappingView.runMapping();
  }
}
