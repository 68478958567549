import MovementDefinition from '../movementDefinition';
import Coordinate from '../coordinate';
import MovementRule from '../movementRule';

// An iterator for moving around a grid.
// The way we move through the grid is based on a MovementDefinition
// This will work with any grid that responds to:
// grid.get(Coordinate coord) => object
// grid.numRows => integer
// grid.numCols => integer
// grid.toMatrix() => [[...], [...], [...], ...]
//
// In this class we use the terms "primary" and "secondary". Those equate to rows/cols,
//  or x, y. We're using primary and secondary because whether we iterate first over all the rows,
//  then over all the columns, or vice-versa is configurable.
export default class GridIterator {
  constructor(grid, movementDefinition, options = null) {
    this.grid = grid;
    this.movement = movementDefinition || new MovementDefinition();
    this.visitedPositions = {};
    this.options = options || {};
    this.name = this.options.name || 'Unnamed GridIterator'; // This is to help me identify which iterator is running for debugging
    this._finished = false;
    this._hasCurrent = grid.numCols > 0 && grid.numRows > 0;
    this.subgrids = [];
    this.subgridIndex = null;

    this._resetBothAxes();
    this._createSubgrids();
  }

  // Check for subgrids before doing iteration
  each(func) {
    if (this._hasSubgrids) { // Pass the iteration off to subgrids
      this.subgrids.forEach((subgrid) => {
        console.log(`>>>Load grid ${subgrid.name}`);
        subgrid.each(func);
      });
    } else { // Do the actual iteration
      this._each(func);
    }
  }

  // Do the actual iteration
  _each(func) {
    console.log('>>>Load ---- no subgrids.');
    while (this.hasCurrent()) {
      console.log(`each() in grid ${this.name} is at (row, col): `, this.row, this.col);
      func.call(this, this.current());

      if (this.hasMore()) {
        this.next();
      } else {
        this._hasCurrent = false;
      }
    }
  }

  hasCurrent() {
    return this._hasCurrent;
  }

  next() {
    const curr = this.current();
    this.increment();
    this._prohibitInfiniteLoops();
    return curr;
  }

  finished() {
    // Handle subgrids
    if (this._hasSubgrids) {
      if (this.subgrids.length - 1 > this.subgridIndex) {
        return false;
      } else if (this.subgrids[this.subgrids.length - 1].finished()) {
        this._finished = true;
      }
      return this._finished;
    }

    // Handle my own grid self
    const rule1 = this._primaryRule;
    const rule2 = this._secondaryRule;
    const value1 = this.primaryIndex;
    const value2 = this.secondaryIndex;

    if (rule1.end == value1 && rule2.end == value2) {
      this._finished = true;
    }
    console.log(`${this.name} finished? ${rule1.end}==${value1} && ${rule2.end}==${value2} ===> ${this._finished}`);

    return this._finished;
  }

  current() {
    if (this._hasSubgrids) {
      return this._currentSubgrid.current();
    } else {
      return this._current();
    }
  }

  _current() {
    if (this.col < 0 || this.row < 0) {
      return null;
    }

    if (this.col < this.numCols && this.row < this.numRows) {
      const coordinate = new Coordinate({ rowIndex: this.row, columnIndex: this.col });
      this.log('GET well', coordinate.toString());
      return this.grid.get(coordinate);
    }

    return null;
  }

  setCurrent(data) {
    const curr = this.current();
    curr.set(data);
  }

  hasMore() {
    return !this._finished;
  }

  get numRows() {
    return this.grid.numRows;
  }

  get numCols() {
    return this.grid.numCols;
  }

  _prohibitInfiniteLoops() {
    if (this._hasSubgrids && this._currentSubgrid) {
      this._currentSubgrid._prohibitInfiniteLoops();
      return;
    }

    if (this.visitedPositions[this.currentPositionId]) {
      throw new Error(`GridIterator should not revisit the same position: ${this.name} ${this.currentPositionId}`);
    }
    this.visitedPositions[this.currentPositionId] = true;
  }

  get currentPositionId() {
    return `${this.name}:${this.col},${this.row}`;
  }

  get _primaryRule() {
    return new MovementRule(this.numRows, this.numCols, this.movement, this.primaryDirection);
  }

  get _secondaryRule() {
    return new MovementRule(this.numRows, this.numCols, this.movement, this.secondaryDirection);
  }

  // To handle wrapping midway through the grid, we can create two subgrids
  // and iterate through each of them in turn.
  // Right now it only works for wrapping at a certain column. You can't use it yet
  // for wrapping after hitting a particular row.
  _createSubgrids() {
    // It might be that we don't want to hardcode this as effecting column?
    // Or rename the field in the UI to make it clear that it effects column and not row?
    if (this.movement.wrapAt > 0 && this.movement.wrapAt < this.grid.numCols) {
      // Split this gridIterator into two subgrids
      const movement1 = this.movement.copy({ stopCol: this.movement.wrapAt - 1, wrapAt: null });
      const movement2 = this.movement.copy({ startCol: this.movement.wrapAt, wrapAt: null });
      this.subgrids.push(new GridIterator(
          this.grid,
          movement1,
          {name: 'subGrid1'}
      ));
      this.subgrids.push(new GridIterator(
          this.grid,
          movement2,
          {name: 'subGrid2'}
      ));
      this.subgridIndex = 0;

      if (this.movement.horizontalDirection == 'left') { // swap grid1 and grid2 so they're handled in the opposite order
        this.subgrids.reverse();
      }
    }
  }

  get _currentSubgrid() {
    return this.subgrids[this.subgridIndex];
  }

  _incrementSubgrid() {
    return this.subgridIndex++;
  }

  increment() {
    if (this._hasSubgrids) { // Pass the iteration off to subgrids
      if (this._currentSubgrid.finished()) {
        this._incrementSubgrid();
      } else {
        this._currentSubgrid.increment();
      }
    } else {
      this._increment();
    }
  }

  _increment() {
    const rule1 = this._primaryRule;
    // if (this.name == 'subGrid1') {
    //   console.log(`col row: ${this.name} ${this.col}, ${this.row}`);
    //   debugger;
    // }
    // e.g. this.col += 1
    this._incrementBy({
      fieldName: rule1.field,
      by: rule1.incrementAmount
    });

    if (this._outOfBounds(this.primaryIndex, rule1)) {
      this.log('>> Primary is out of bounds');
      this._resetAxis(rule1);
      this._incrementSecondaryAxis();
    }
    this.showInLog({});

    return this.finished();
  }

  get _hasSubgrids() {
    return this.subgrids.length > 0;
  }

  get primaryDirection() {
    return this.movement.primaryDirection;
  }

  get secondaryDirection() {
    return this.movement.secondaryDirection;
  }

  showInLog(extraInfo) {
    if (this.grid.numCols != 24) {
      return false;
    }

    this.log(`>>>> Grid - current position <<<<`);
    this.log(extraInfo);
    for (let row = 0; row < this.grid.numRows; row++) {
      const arr = Array(this.grid.numCols).fill('');
      if (row == this.row) {
        arr[this.col] = 'X';
      }
      this.log(arr.toString());
    }
    this.log(`======================`);
  }

  // e.g. this.col += 1, or this.row += 1
  _incrementBy(attrs = { fieldName: null, by: 0 }) {
    this.log(`== incrementBy ${attrs.fieldName} += ${attrs.by}`);
    this[attrs.fieldName] += attrs.by;
  }

  _outOfBounds(value, rule) {
    if (value < rule.min || value > rule.max) {
      return true;
    }

    return false;
  }

  get primaryIndex() {
    const rule = this._primaryRule;
    return this[rule.field];
  }

  get secondaryIndex() {
    const rule = this._secondaryRule;
    return this[rule.field];
  }

  _incrementSecondaryAxis() {
    const rule = this._secondaryRule;

    this._incrementBy({
      fieldName: rule.field,
      by: rule.incrementAmount
    });

    if (this._outOfBounds(this.secondaryIndex, rule)) {
      return false;
    }

    return true;
  }

  _resetAxis(rule) {
    this.log(`>> Reset ${rule.field} to ${rule.start} in ${this.name}`);
    this._reset({
      fieldName: rule.field,
      value: rule.start
    });
  }

  _resetBothAxes() {
    const rule1 = this._primaryRule;
    const rule2 = this._secondaryRule;

    this._resetAxis(rule1);
    this._resetAxis(rule2);
  }

  // Initialize or reset col or row to their starting value.
  _reset(attrs = { fieldName: '?', value: 0 }) {
    this[attrs.fieldName] = attrs.value;
  }

  log(...info) {
    // if (this.name == 'destMiddlemanLayer') {
    console.log(...info);
    // }
  }
}
