import { newId } from '../newId';

/*
 * What's the difference between an existing plate set with things already set,
 * and a new plate set where new positions and contents can be added?
 */
export default class PlateSetLayer {
  constructor(attrs = { id: null, name: null, numCols: null, numRows: null }) {
    this.id = attrs.id || newId();
    this.name = attrs.name || `Layer ${this.id}`;
    this.numCols = attrs.numCols;
    this.numRows = attrs.numRows;
  }
}
