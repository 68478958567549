// Whereas a MovementDefinition is a simple data object with no associations or logic,
// a MovementRule is the combination of a MovementDefinition with a specific grid.
// This allows the MovementRule to calculate values of the MovementDefinition as they should
// apply to a grid of a certain size.
export default class MovementRule {
  constructor(numRows, numCols, movementDefinition, direction) {
    this.numRows = numRows;
    this.numCols = numCols;
    this.movement = movementDefinition;
    this._rule = this.rules[direction];
  }

  get field() {
    return this._rule.field;
  }

  get start() {
    return this._rule.start;
  }

  get end() {
    return this._rule.end;
  }

  get min() {
    return this._rule.min;
  }

  get max() {
    return this._rule.max;
  }

  get incrementAmount() {
    return this._rule.incrementAmount;
  }

  get wrapAt() {
    return this._rule.wrapAt;
  }

  get isForward() {
    return (this.direction == 'right' || this.direction == 'down');
  }

  get isReverse() {
    return !this.isForward;
  }


  // WORKING ON:
  // Allowing the movement definition and rules to put a bounding box on the movement
  // rather than always iterating the entire grid.
  // Then I can create subgrid iterators that handle different parts of the grid separately
  // which could be a nice way to handle things like wrapping mid-grid, or allowing
  // different sections of the grid to be iterated in different ways
  //
  // Whereas the movement definition is generic, that is, it doesn't know anything about
  // a particular grid. These rules are basically the same as the movement definition
  // but with the particulars of a specific grid included in them.
  get rules() {
    return {
      right: {
        field: 'col',
        start: this.movement.startCol || 0,
        end: this.calcMin(this.movement.stopCol, this.numCols - 1),
        min: this.movement.startCol || 0, // Note that min/max are the same as start/end, except that they might be switched depending on the direction.
        max: this.calcMin(this.movement.stopCol, this.numCols - 1),
        incrementAmount: 1,
        wrapAt: this.movement.wrapAt
      },
      left: {
        field: 'col',
        start: this.calcMin(this.movement.stopCol, this.numCols - 1),
        end: this.movement.startCol || 0,
        min: this.movement.startCol || 0,
        max: this.calcMin(this.movement.stopCol, this.numCols - 1),
        incrementAmount: -1,
        wrapAt: this.movement.wrapAt
      },
      down: {
        field: 'row',
        start: this.movement.startRow || 0,
        end: this.calcMin(this.movement.stopRow, this.numRows - 1),
        min: this.movement.startRow || 0,
        max: this.calcMin(this.movement.stopRow, this.numRows - 1),
        incrementAmount: 1,
        wrapAt: this.movement.wrapAt
      },
      up: {
        field: 'row',
        start: this.calcMin(this.movement.stopRow, this.numRows - 1),
        end: this.movement.startRow || 0,
        min: this.movement.startRow || 0,
        max: this.calcMin(this.movement.stopRow, this.numRows - 1),
        incrementAmount: -1,
        wrapAt: this.movement.wrapAt
      }
    };
  }

  // Find the maximum of two numbers, and account for things like undefined
  calcMax(x, y) {
    if (x == null || x == undefined || isNaN(x)) {
      return y;
    }
    if (y == null || y == undefined || isNaN(y)) {
      return x;
    }
    return Math.max(x, y);
  }

  calcMin(x, y) {
    if (x == null || x == undefined || isNaN(x)) {
      return y;
    }
    if (y == null || y == undefined || isNaN(y)) {
      return x;
    }
    return Math.min(x, y);
  }
}
