import Color from 'color';

/* Creates a mapping between names of things and colors 
 * e.g.
 * service = new ColorService(10);
 * service.getColor('Bacteria A') => '#5621AC'
 * service.getColor('Bacteria B') => '#44C4FB'
 *
 * It will limit the set of colors to 10 (or the number that you give it)
 * When it runs out of colors it will restart back at the first color.
 */
export default class ColorService {
  constructor(attrs = { numColors: null, onlyDarkColors: null }) {
    this.colorMap = {};
    this.numColors = attrs.numColors || 96;
    this.onlyDarkColors = attrs.onlyDarkColors || false;
    if (this.onlyDarkColors) {
      this.colorList = ColorService.darkColors(); // Use a fixed list
    } else {
      // Dynamically generate them
      this.colorList = ColorService.get(this.numColors);
    }
    this.currentIndex = 0;
    this.defaultColor = 'rgba(0, 0, 0, 0)';
  }

  getColor(name) {
    if (!name || name == '') {
      return this.defaultColor;
    }

    if (name in this.colorMap) {
      return this.colorMap[name];
    } else {
      const color = this.next();
      this.colorMap[name] = color;
      return this.colorMap[name];
    }
  }

  // I'm appending "-solid" to the identifier so that we don't start with the
  // same color as when we're not titrating the colors. Mostly because I need
  // dark colors, in a format that are easy to lighten as we move through the
  // range.
  // This might could use a bit more thinking.
  getColorOnScale(name, value, range) {
    const baseColor = this.getColor(name);
    const percentage = range.calculatePercentage(value);
    const color = this.lighten(baseColor, (1.0-percentage));
    return color;
  }

  lighten(color, percentage) {
    const colorObj = Color(color);
    return colorObj.lighten(percentage);
  }

  next() {
    const color = this.colorList[this.currentIndex % this.colorList.length];
    this.currentIndex++;
    return color;
  }

  static get(howMany) {
    let colors = [];
    while (colors.length < howMany) {
      colors = colors.concat(this.getABunchOfColorsInHSL(0, 30, 82, 56));
      colors = colors.concat(this.getABunchOfColorsInHSL(10, 30, 92, 87));
      colors = colors.concat(this.getABunchOfColorsInHSL(15, 30, 64, 35));
      colors = colors.concat(this.getABunchOfColorsInHSL(23, 30, 23, 61));
    }
    return colors;
  }

  // This option has a set of hard-coded colors.
  static all() {
    return ['#C84E00', '#E89923', '#FFD960', '#A1B70D', '#339898', '#1D6363', '#005587', '#0577B1', '#993399', '#E2E6ED', '#F3F2F1', '#FCF7E5', '#988675', '#DAD0C6', '#262626', '#666666', '#B5B5B5', '#E5E5E5'];
  }

  static darkColors() {
    return ['#C84E00', '#829410', '#339898', '#E89923', '#1D6363', '#005587', '#0577B1', '#FFD960', '#993399', '#E2E6ED', '#F3F2F1', '#FCF7E5', '#988675', '#DAD0C6', '#262626', '#666666', '#B5B5B5', '#E5E5E5'];
  }

  static getABunchOfColorsInHSL(start = 0, inc = 30, saturation = 82, lightness = 56, alpha = 1) {
    const colors = [];
    for (let deg = 0; deg < 360; deg += inc) {
      colors.push(`hsl(${deg}deg ${saturation}% ${lightness}% / ${alpha})`);
    }
    return colors;
  }

  // This option will create new colors based on a given index in a list.
  static createOne(index) {
    const hue = index * 137.508; // use golden angle approximation
    return `hsl(${hue},50%,75%)`;
  }

  static hslIsDark(color) {
    console.log('isDark', color);
    color = `${color}`;

    // Check the format of the color, HEX or RGB?
    const matches = color.match(/^hsl\(\d+deg (\d+)% (\d+)%.*?/);
    if (matches) {
      console.log('color', color, matches[1]);

      // const saturation = parseInt(matches[1], 10);
      const luminosity = parseInt(matches[2], 10);

      if (luminosity < 42) {
        console.log('isDark');
        return true;
      } else {
        console.log('is light');
        return false;
      }
    }

    return false;
  }
}
