import RepeatDefinition from './repeatDefinition.js';
import IteratorFactory from '../plateIterators/iteratorFactory.js';
import LayerIterator from '../plateIterators/layerIterator.js';
import { newId } from '../newId.js';
import Layer from '../layer.js';
import Well from '../well.js';

export default class ContainerMap {
  constructor(mappingId, sourcePlateSet, sourceMovementDef, destPlateSet, destMovementDef, repeatDef = null) {
    this.mappingId = mappingId;
    this.sourcePlateSet = sourcePlateSet;
    this.sourceMovementDef = sourceMovementDef,
    this.destPlateSet = destPlateSet;
    this.destMovementDef = destMovementDef;
    this.repeatDef = repeatDef || new RepeatDefinition();
    this.id = newId();
  }

  mapPlateSet() {
    this.clearTemporaryMappingPlatesAndLayers(this.destPlateSet, this.mappingId);

    console.log('sourcePlateSetLayers', this.sourcePlateSet.layers.map((x) => x.id));
    console.log('plate layers');
    this.sourcePlateSet.plates.forEach((plate) => {
      console.log(plate.id(), ' => ', plate.layers.map((l) => l.id));
    });

    this.sourcePlateSet.layers.forEach((layer) => {
      this.mapPlateSetLayer(layer, this.sourcePlateSet, this.destPlateSet);
    });
  }

  // While the user is actively working on the mapping, layers are added to the destination plate
  // each time the mapping is re-run, those temporary layers need to be removed before more layers are added.
  // Then once the mapping is complete, the layers are no longer considered tempoary.
  clearTemporaryMappingPlatesAndLayers(destPlateSet, mappingId) {
    destPlateSet.plates.forEach((plate) => {
      if (this.mappingId !== null) {
        if (plate.mappingId == this.mappingId) {
          // This plate was added by the mapping, remove it!
          destPlateSet.removePlate(plate.id);
        } else {
          // Just try to remove layers from an existing plate
          const oldLayers = plate.layers.filter((layer) => layer.mappingId == this.mappingId);
          oldLayers.forEach((layer) => {
            plate.removeLayer(layer.id);
          });
        }
      }
    });
  }

  // We are mapping one layer at a time.
  // We map it by doing the following:
  // 1. Copy the contents of the layer, from all plates into one
  //    big, temporary layer ("middlemanLayer"). This allows us to do the mapping
  //    without worrying about coordinate complexity around plate boundaries.
  //    We could probably do it without this step, then you just have to do more conversions of coordinates
  // 2. Perform the mapping, including direction, skip, repeat, etc, into another
  //    new big, temporary layer ("destMiddlemanLayer").
  // 3. Break the destMiddlemanLayer into individual plates.
  mapPlateSetLayer(plateSetLayer, plateSet, destPlateSet, skipEmpties = false) {
    console.log('mapPlateSetLayer()', plateSetLayer);

    const middlemanLayer = new Layer({
      numRows: plateSet.numRows * plateSet.numPlates(),
      numCols: plateSet.numCols
    });

    plateSet.eachWell(plateSetLayer.id, (well, rowIndex, colIndex) => {
      middlemanLayer.setWell(rowIndex, colIndex, well);
    });

    middlemanLayer.showInLog({
      use: 'middlemanLayer',
      plate: 'from source all plates combined'
    });

    const sourceIterator = IteratorFactory.fromMovementDefinition(
        this.sourceMovementDef,
        middlemanLayer,
        { name: 'middlemanLayer' }
    );

    const numDestWells = plateSet.numWells() * this.repeatDef.repeat;
    console.log('numDestWells', numDestWells);
    const numDestRows = Math.ceil(numDestWells / destPlateSet.numCols);
    const numDestCols = destPlateSet.numCols; // Leave the plate width the same. We're just calculating how many rows/plates to add

    const destMiddlemanLayer = new Layer({
      numRows: numDestRows,
      numCols: numDestCols,
      mappingId: this.mappingId
    });

    const destinationIterator = IteratorFactory.fromMovementDefinition(
        this.destMovementDef,
        destMiddlemanLayer,
        { name: 'destMiddlemanLayer' }
    );

    destMiddlemanLayer.showInLog({
      use: 'destMiddlemanLayer',
      plate: 'from middlemanLayer'
    });

    try {
      let repeatWells = [];
      sourceIterator.each((sourceWell) => {
        const destWell = destinationIterator.next();
        const sourceP = sourceWell.coordinate.toString();
        const desP = destWell.coordinate.toString();
        const value = sourceWell.name();
        if (value == '56' || value == '57') {
          debugger;
        }
        console.log(`Copy ${sourceP} => ${desP} (value: ${value})`);
        Well.copy(sourceWell, destWell);

        // We reached the number we're supposed to skip before repeating
        // So we should start repeating now.
        // And do it the defined number of repeat times
        if (this.repeatDef.repeat > 1) {
          repeatWells.push(sourceWell);
          if (repeatWells.length > this.repeatDef.skip) {
            for (let repeatIndex = 0; repeatIndex < this.repeatDef.repeat - 1; repeatIndex++) {
              for (let wellIndex = 0; wellIndex < repeatWells.length; wellIndex++) {
                sourceWell = repeatWells[wellIndex];
                const destWell = destinationIterator.next();
                Well.copy(sourceWell, destWell);
              }
            }
            repeatWells = [];
          }
        }
      });
    } catch (e) {
      console.log(e);
      alert(e);
    }

    // Now break the destMiddleMan layer up into separate layers for each plate
    // and skip wells that should not be filled based on the plateLayout rules
    const finalLayer = destPlateSet.createLayer(plateSetLayer.name);

    let plateIndex = 0;
    let destPlate = destPlateSet.findOrCreatePlateByIndex(plateIndex);
    let layer = destPlate.getLayer(finalLayer.id);
    let layerIterator = new LayerIterator(layer);
    const tag = plateSetLayer.name;

    destMiddlemanLayer.eachWell((sourceWell) => {
      if (sourceWell.hasContents || !skipEmpties) {
        console.log(`>>source well has content ${sourceWell.coordinate}`);
        let destWell = layerIterator.nextWithTag(plateSetLayer.name);

        // If we couldn't find a destWell, then create a new plate and find one there
        if (destWell == null) {
          plateIndex++;
          destPlate = destPlateSet.findOrCreatePlateByIndex(plateIndex);
          layer = destPlate.getLayer(finalLayer.id);
          layerIterator = new LayerIterator(layer);
          destWell = layerIterator.nextWithTag(tag);
        }

        if (destWell == null) {
          console.log(`This plate layout does not allow contents with tag: ${tag}`);
        } else {
          console.log(`COPYING ${sourceWell.coordinate} -> ${destWell.coordinate}`);
          Well.copy(sourceWell, destWell);
        }
      }
    });
  }

  logLayer(layer) {
    layer.writeToLog();
  }

  mapPlate(sourcePlate, destPlateSet) {
    this.plate.visibleLayers.forEach((layer) => {
      this.mapLayer(layer, destPlateSet);
    });
  }

  copyWell(sourceWell, destWell) {
    Well.copy(sourceWell, destWell);
  }
}
