import Papa from 'papaparse';

export default class PlateClipboard {

  // With a clipboard event
  static copy1(plateSet, clipboardData) {
    console.log(PlateClipboard.plateSetToTSV(plateSet));
    // clipboardData.setData('mylab/text/json', PlateClipboard.plateToJson(plate));

    // This is a little hacky. I found that I can write to the system clipboard
    // with event.clipboardData.setData('mylab/text/json', xyz);
    // but if I don't have a clipboard event (command-c), and instead
    // trigger a copy from clicking a menu item then it won't let me write
    // to 'mylab/text/json'. So I'm using an internal clipboard for
    // copying and pasting between plates.
    window.internalClipboard = PlateClipboard.plateSetToJson(plateSet);
    console.log('copy; window.internalClipboard', window.internalClipboard);
    clipboardData.setData('text/plain', PlateClipboard.plateSetToTSV(plateSet));
  }

  static paste1(targetPlate, clipboardData) {
    // Parse from internal copy/paste
    let clipboard = window.internalClipboard;
    if (clipboard != '') {
      const wellData = JSON.parse(clipboard);
      targetPlate.paste(wellData);
      return true;
    }

    // Note! If the pasting above, worked, then we don't need to try other
    // clipboard sources. If we do then we might paste in duplicate.

    // Parse from system clipboard (e.g. Excel)
    clipboard = clipboardData.getData('text/plain');
    if (clipboard != '') {
      const wellData = Papa.parse(clipboard, { delimiter: '\t' });
      targetPlate.pasteTSV(wellData.data);
      return true;
    }

    return false;
  }

  // Without a clipboard event
  static async copy2(plate) {
    const message = PlateClipboard.plateToTSV(plate);

    console.log('try copy2');

    window.internalClipboard = PlateClipboard.plateToJson(plate);

    navigator.permissions.query({ name: 'clipboard-write' }).then(async (result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        /* write to the clipboard now */
        if (result.state === 'granted') {
          const type = 'text/plain';
          const blob = new Blob([message], { type });
          const data = [new ClipboardItem({ [type]: blob })];
          navigator.clipboard.write(data).then(function() {
            console.log('copy2 Success');
          }, function() {
            console.log('copy2 Error');
          });
        }
      }
    });
  }

  static plateSetToJson(plateSet) {
    // Copy our own JSON format into internal memory
    // for pasting into another plate in the app.
    const wells = [];
    plateSet.plates.forEach((plate) => {
      wells.push(...plate.selectedWells());
    });
    const wellData = wells.map((well) => well.state);
    return JSON.stringify(wellData);
  }

  static plateSetToTSV(plateSet) {
    // Copy TSV to the system clipboard, for pasting into Excel
    const wells = [];
    plateSet.plates.forEach((plate) => {
      console.log('for plate got', plate.selectedWellsMatrix());
      wells.push(...plate.selectedWellsMatrix());
    });
    console.log('wells...', wells);
    const nameMatrix = wells.map((row) => {
      return row.map((well) => {
        return well.name();
      });
    });
    const tsv = Papa.unparse(nameMatrix, { delimiter: '\t' });
    console.log('fnal tsv', tsv);
    return tsv;
  }
}
