/*
* Like an ActiveRecord Model
* the job of this class is to update it's own state,
* and to have a few public methods to let other people send it messages
* based on which it can update it's state.
*
*/
import Layer from '../lib/layer';

export default class PlatePanelModel {
  constructor(state = null) {
    this.state = state || PlatePanelModel.defaultState();
  }

  static defaultState() {
    return {
      plateSets: [],
      selectedItems: {
        type: null,
        parent: null,
        items: []
      }
    };
  }

  get plateSets() {
    return Object.values(this.state.plateSets);
  }

  // addPlate(plate) {
  //   this.state.plates.push(plate);
  //   this.selectPlate(plate.id());
  // }

  addPlateSet(plateSet) {
    this.state.plateSets.push(plateSet);
    this.selectPlateSet(plateSet.id());
  }

  selectPlateSet(plateSetId) {
    this.plateSets.forEach((plateSet) => {
      console.log('<>', plateSet.id(), plateSetId);
      if (plateSet.id() == plateSetId) {
        plateSet.select();
        this.captureSelected('plateSet', [plateSet]);
      } else {
        plateSet.deselect();
      }
    });
  }

  captureSelected(type, parent, items) {
    this.state.selectedItems = {
      type: type,
      parent: parent,
      selectedItems: items
    };
  }

  deleteSelected() {
    // TODO handle deleting plates and plateSets
    const selection = this.state.selectedItems;
    if (selection.type == 'well') {
      selection.parent.deleteSelected(selection);
    } else if (selection.type != null) {
      console.log(`Warning: deleting this type of data is not yet supported. Type: ${selection.type}`);
    }
  }

  selectPlate(plateId) {
    const plate = this.getPlate(plateId);
    plate.select();

    // TODO: Instead of null, we need to pass the parent plateSet
    this.captureSelected('plate', null, plate);
  }

  togglePlateSelected(plateId) {
    const plate = this.getPlate(plateId);
    if (plate.isSelected()) {
      plate.deselect();
      this.captureSelected(null, null, []); // Maybe this should revert to selecting the plateSet?
    } else {
      plate.select();
      // TODO: Instead of null, we need to pass the parent plateSet
      this.captureSelected('plate', null, plate);
    }
  }

  getPlate(id) {
    console.log('platePandelModel#getPlate', id);
    const plates = this.plateSets.flatMap((ps) => (ps.plates));
    return plates.find((plates) => (plates.id() == id));
  }

  getPlateSet(id) {
    console.log('platePandelModel#getPlateSet', id);
    console.log(this.plates);
    return this.plateSets.find((plateSet) => (plateSet.id() == id));
  }

  selectedPlateSet() {
    return this.plateSets.find((x) => x.isSelected());
  }

  updateWell(plateId, wellId, field, value) {
    this.getPlate(plateId).updateWell(wellId, field, value);
  }

  updatePlateAttribute(plateId, field, value) {
    this.getPlate(plateId).setState(field, value);
  }

  toggleLayerVisibility(plateId, layerId) {
    this.getPlate(plateId).toggleLayerVisibility(layerId);
  }

  selectLayer(plateId, layerId) {
    this.getPlate(plateId).selectLayer(layerId);
  }

  addLayer(plateId) {
    this.getPlate(plateId).addLayer(new Layer());
  }

  deleteLayer(plateId, layerId) {
    const plate = this.getPlate(plateId);
    plate.removeLayer(layerId);
  }

  updateLayerAttribute(plateId, layerId, field, value) {
    this.getPlate(plateId).updateLayerAttribute(layerId, field, value);
  }

  toJson() {
    console.log('platePanelModel1', this.state.plates);
    const json = this.state.plates.map((plate) => {
      return plate.toJson();
    });
    console.log('platePanelModel2', json);
    return json;
  }
}
