import PlatePanelController from './appController.js';
import ToolbarController from './components/toolbar/toolbarController.js';

import EventManager from './lib/eventManager.js';
import Toolbar from './models/toolbar.js';

import MenubarView from './components/menubar/menubarView.js';
import ToolbarView from './components/toolbar/toolbarView.js';
import NewPlateModal from './components/newPlateModal/newPlateModalView.js';
import PlatePanelView from './components/platePanel/platePanelView.js';
import PlateInspectorView from './components/plateInspector/plateInspectorView.js';
import OpenPlatesView from './components/openPlates/openPlatesView.js';
import PlateMappingView from './components/plateMapping/plateMappingView.js';

import PlatePanelModel from './models/platePanelModel.js';
import EmptyModel from './models/emptyModel.js';

import Authentication from './lib/authentication.js';

import './components/window.css';
import './components/button.css';
import './components/menubar/menubar.css';
import './components/toolbar/toolbar.css';
import './components/plateInspector/plateInspector.css';
import './components/plate/plate.css';
import './components/plate/well.css';
import './components/newPlateModal/newPlateModal.css';
import './components/openPlates/openPlates.css';
import './components/plateMapping/plateMapping.css';
import './components/platePanel/platePanel.css';
import './assets/fontawesome/css/fontawesome.css';
import './assets/fontawesome/css/brands.css';
import './assets/fontawesome/css/solid.css';
import './main.css';

export default class App {
  constructor(scope) {
    this.scope = scope;
    this.eventManager = new EventManager();

    this.models = {
      platePanel: new PlatePanelModel(),
      menubar: new EmptyModel(),
      toolbar: new Toolbar(this.eventManager),
      newPlateModal: new EmptyModel()
    };

    console.log('this.models.toolbar.state', this.models.toolbar.state);
    this.views = {
      menubar: new MenubarView(this.scope, this.models.menubar.state, this.eventManager),
      toolbar: new ToolbarView(this.scope, this.models.toolbar.state, this.eventManager),
      newPlateModal: new NewPlateModal(this.scope, this.models.newPlateModal.state, this.eventManager),
      platePanel: new PlatePanelView(this.scope, this.models.platePanel.state, this.eventManager),
      plateInspector: new PlateInspectorView(this.scope, this.models.platePanel.state, this.eventManager),
      openPlates: new OpenPlatesView(this.scope, this.models.platePanel.state, this.eventManager),
      plateMapping: new PlateMappingView(this.scope, this.models.platePanel.state, this.eventManager)
    };

    this.controllers = {
      'platePanel': new PlatePanelController(this.models.platePanel, this.views),
      'toolbar': new ToolbarController(this.models.toolbar, this.views)
    };
  }

  render() {
    Object.values(this.views).forEach((view) => {
      this.append(view);
      view.initEvents(this);
    });

    this.initEvents();
    Authentication.init();
  }

  initEvents() {
    // this.initDraggable();

    // This registers all the event handler "actions"
    // in each controller, by using what they have defined in routes
    Object.values(this.controllers).forEach((controller) => {
      Object.keys(controller.routes).forEach((routePath) => {
        const actionHandler = controller.routes[routePath];
        this.eventManager.register(this, routePath, actionHandler);
      });
    });
  }

  append(obj) {
    this.scope.append(obj.render());
  }
}
