import Well from '../well';
import MovementDefinition from '../movementDefinition';
import GridIterator from './gridIterator';

export default class LayerIterator {
  constructor(layer, movementDefinition, options = { name: 'Unnamed Iterator' }) {
    this.layer = layer;
    this.movement = movementDefinition || new MovementDefinition();
    this.gridIterator = new GridIterator(this.layer, this.movement, options);
    this.options = options;
    this.seen = [];
  }

  each(func) {
    return this.gridIterator.each(func);
  }

  next() {
    return this.gridIterator.next();
  }

  increment() {
    return this.gridIterator.increment();
  }

  finished() {
    return this.gridIterator.finished();
  }

  current() {
    return this.gridIterator.current();
  }

  setCurrent(data) {
    return this.gridIterator.setCurrent(data);
  }

  hasMore() {
    return this.gridIterator.hasMore();
  }

  // -------
  // -------

  // Return then next well in the container that allows the given tag.
  // Or if there are none remaining, then return null.
  nextWithTag(tag) {
    let well = this.next();

    while (well != null && !this.wellAllowsTag(well, tag)) {
      well = this.next();
    }

    return well;
  }

  wellAllowsTag(well, tag) {
    return this.layer.allowsTag(well.coordinate, tag);
  }

  // setNextWell(sourceWell) {
  //   const destWell = this.next();
  //   const src = sourceWell ? sourceWell.coordinate.toString() : 'null';
  //   const dest = destWell ? destWell.coordinate.toString() : 'null';
  //   if (dest != 'null' && this.seen.includes(dest)) {
  //     console.log('uhoh, overwriting ', dest);
  //     debugger;
  //   }
  //   this.seen.push(dest);

  //   console.log(`COPY ====> ${src} => ${dest}`);
  //   Well.copy(sourceWell, destWell);

  //   this.layer.showInLog({at: '||<<<<<'});
  // }
}
