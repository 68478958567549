import { newId } from './newId';
import Layer from './layer';
import ColorService from './colorService';
import Well from './well';
import PlateLayout from './plate/plateLayout';
import Coordinate from './coordinate';

export default class Plate {
  constructor(attrs = { id: null, name: null, numCols: null, numRows: null, selected: null, layers: null, mappingId: null, layout: null, layoutId: null, plateSetId: null }) {
    this.eventListeners = [];
    this._layers = {};
    this.mappingId = attrs.mappingId;

    if (attrs.layout == null) {
      attrs.layout = PlateLayout.fakeFind(attrs.layoutId);
    }

    this.state = {
      id: attrs.id || newId(),
      name: attrs.name || null,
      numCols: attrs.numCols || 12,
      numRows: attrs.numRows || 8,
      selected: attrs.selected || false,
      colorDisplay: attrs.colorDisplay || 'content',
      selectedLayerId: attrs.selectedLayerId || null,
      plateSetId: attrs.plateSetId,
      layout: attrs.layout,
      layoutId: attrs.layout.id
    };


    // this.state.layout = attrs.layout || new PlateLayout({numCols: this.state.numCols, numRows: this.state.numRows });

    // These depend on attributes set earlier
    this.state = {
      ...this.state,
      // rows: Array(this.state.numRows), I don't think this is used anhymore? now that we have layers
      name: this.state.name || this.state.id
    };

    // if (attrs.layers) {
    //   attrs.layers.forEach((layerAttrs) => {
    //     this.addLayer(Layer.fromJson(layerAttrs));
    //   });
    // } else {
    //   this.addEmptyLayer();
    // }

    this.colorService = new ColorService();
  }

  id() {
    return this.getState('id');
  }

  onScreenName() {
    if (this.state.name !== this.id()) {
      return `${this.state.name} (${this.id()})`;
    } else {
      return `Plate ${this.id()}`;
    }
  }

  toJson() {
    console.log('plate1', this.state);
    const data = {
      ...this.state,
      layers: Object.values(this._layers).map((layer) => {
        return layer.toJson();
      })
    };
    console.log('plate2', this.state);

    return data;
  }

  addEmptyLayer() {
    const layer = new Layer({ id: null, numCols: this.numCols(), numRows: this.numRows() });
    this.addLayer(layer);
  }

  getState(key) {
    return this.state[key];
  }

  setState(key, value) {
    this.state[key] = value;
  }

  numCols() {
    return this.getState('numCols');
  }

  width() {
    return this.numCols();
  }

  numRows() {
    return this.getState('numRows');
  }

  height() {
    return this.numRows();
  }

  numLayers() {
    return this.layers.length;
  }

  get layers() {
    return Object.values(this._layers);
  }

  get layout() {
    return this.state.layout;
  }

  select() {
    this.setState('selected', true);
    // this.trigger('plateSelected');
  }

  deselect() {
    this.setState('selected', false);
    this.trigger('plateDeselected');
  }

  isSelected() {
    return this.state.selected;
  }

  selectWells(wellIds, options = {deslectOthers: true}) {
    this.selectedLayer().selectWells(wellIds, options);
    console.log('for layer, selected wells are', this.selectedLayer().selectedWells());
    console.log('done selecting wells');
  }

  deselectWells() {
    this.selectedLayer().deselectWells();
  }

  selectedWells() {
    return this.selectedLayer().selectedWells();
  }

  selectedWellsMatrix() {
    return this.selectedLayer().selectedWellsMatrix();
  }

  get(coordinate) {
    // const coord = new Coordinate({ rowIndex: rowIndex, columnIndex: colIndex });
    return this.selectedLayer().get(coordinate);
  }

  updateWell(wellId, field, value) {
    this.selectedLayer().eachWell((well) => {
      if (well.id() === wellId) {
        well.setState(field, value);
      }
    });
  }

  resize(size = { height: null, width: null }) {
    console.log('!! plate.resize() is turned off');
    // if (size.height == null || size.width == null) {
    //   return false;
    // }

    // this.setState('height', size.height);
    // this.setState('width', size.width);
  }

  paste(pastedWells) {
    if (pastedWells.length == 0) {
      return;
    }

    console.log('pastedWells', pastedWells);
    const wells = [];
    let startingWell = this.selectedWells()[0];
    const coordinate = new Coordinate({ rowIndex: 0, columnIndex: 0 });
    startingWell = startingWell || this.get(coordinate);
    const startRow = startingWell.rowNum();
    const startCol = startingWell.colNum();

    const rowOffset = startRow - pastedWells[0].rowIndex;
    const colOffset = startCol - pastedWells[0].colIndex;

    // Find the adjusted location for where the well will go in the new plate
    // based on the offset of startingWell
    pastedWells.forEach((wellHash) => {
      wellHash.newRowIndex = (wellHash.rowIndex + rowOffset);
      wellHash.newColIndex = (wellHash.colIndex + colOffset);
    });
    console.log('pastedWells 2', pastedWells);

    let sourceRowIndex = 0 - startingWell.rowNum();
    for (let rowIdx = 0; rowIdx < this.numRows(); rowIdx++) {
      const row = [];
      let sourceColIndex = 0 - startingWell.colNum();
      console.log('xyz', sourceRowIndex, sourceColIndex);
      for (let colIdx = 0; colIdx < this.numCols(); colIdx++) {
        const matchingWell = pastedWells.find((wellHash) => {
          return wellHash.newRowIndex == rowIdx && wellHash.newColIndex == colIdx;
        });
        const defaultWell = {
          name: '',
          rowIndex: rowIdx,
          colIndex: colIdx
        };

        const newWellData = matchingWell || defaultWell;
        newWellData.rowIndex = rowIdx;
        newWellData.colIndex = colIdx;
        row.push(new Well(newWellData));
        sourceColIndex++;
      }

      wells.push(row);
      sourceRowIndex++;
    }

    const layer = new Layer({
      id: null,
      numCols: this.numCols(),
      numRows: this.numRows(),
      wells: wells
    });
    this.addLayer(layer);
    this.selectLayer(layer.id);
  }

  valFromMatrix(matrix, row, col) {
    if (row < 0 || col < 0) {
      return null;
    }

    if (matrix.length <= row || matrix[0].length <= col) {
      return null;
    }

    return matrix[row][col];
  }

  pasteTSV(sourceWells) {
    if (!sourceWells || sourceWells.length == 0) {
      return;
    }
    const wells = [];
    let startingWell = this.selectedWells()[0];
    const coordinate = new Coordinate({ rowIndex: 0, columnIndex: 0 });
    startingWell = startingWell || this.get(coordinate);

    // let sourceHeight = sourceWells.length;
    // let sourceWidth = sourceWells[0].length;

    // Uses negative source index offsets so that we don't start adding content
    // until we get to the correct offset in the destination plate.
    let sourceRowIndex = 0 - startingWell.rowNum();
    for (let rowIdx = 0; rowIdx < this.numRows(); rowIdx++) {
      const row = [];
      let sourceColIndex = 0 - startingWell.colNum();
      for (let colIdx = 0; colIdx < this.numCols(); colIdx++) {
        const val = this.valFromMatrix(sourceWells, sourceRowIndex, sourceColIndex) || '';
        row.push(new Well({
          name: val,
          rowIndex: rowIdx,
          colIndex: colIdx
        }));
        sourceColIndex++;
      }

      wells.push(row);
      sourceRowIndex++;
    }

    const layer = new Layer({
      id: null,
      numCols: this.numCols(),
      numRows: this.numRows(),
      wells: wells
    });
    this.addLayer(layer);
    this.selectLayer(layer.id);
  }

  addLayer(layer) {
    if (layer.order == null) {
      const count = this.numLayers();
      layer.order = count;
    }
    layer.setState('layout', this.layout);
    this._layers[layer.id] = layer;
    this.selectLayer(layer.id);
  }

  selectLayer(layerId) {
    this.setState('selectedLayerId', layerId);
    this.eachLayer((layer) => layer.deselect());
    console.log('selectLayer>>', this.state.selectedLayerId);
    console.log(this._layers);

    this._layers[this.state.selectedLayerId].select();
  }

  selectedLayer() {
    return this._layers[this.state.selectedLayerId];
  }

  // For now, all layers are visible...
  visibleLayers() {
    return this.layers.filter((layer) => layer.visible());
  }

  toggleLayerVisibility(layerId) {
    this._layers[layerId].toggleVisiblity();
  }

  currentRows() {
    return this.selectedLayer().wellMatrix();
  }

  displayName() {
    return this.state.name;
  }

  eachLayer(func) {
    const layers = Object.values(this._layers);
    layers.sort((a, b) => {
      return a.order - b.order;
    });
    layers.forEach(func);
  }

  // fillLayerFromCSV(plateSetLayer, file, fileContents) {
  //   const wells = [];
  //   const result = Papa.parse(fileContents);
  //   const data = result.data;
  //   for (let rowIdx = 0; rowIdx < this.numRows(); rowIdx++) {
  //     const row = [];
  //     const dataCells = rowIdx < data.length ? data[rowIdx] : [];
  //     for (let colIdx = 0; colIdx < this.numCols(); colIdx++) {
  //       const element = colIdx < dataCells.length ? dataCells[colIdx] : '';
  //       const value = String(element).trim();
  //       let color = null;
  //       if (value && value != '') {
  //         // TODO: We're parsing the well name twice. We should refactor and just do it once.
  //         const result = Well.parseName(value);
  //         const colorValue = result.label || value;
  //         color = this.colorService.getColor(colorValue);
  //       }

  //       const well = new Well({
  //         name: value,
  //         color: color,
  //         rowIndex: rowIdx,
  //         colIndex: colIdx
  //       });
  //       row.push(well);
  //     }
  //     wells.push(row);
  //   }

  //   const layer = new Layer({
  //     id: plateSetLayer.id,
  //     name: plateSetLayer.name,
  //     numCols: plateSetLayer.numCols,
  //     numRows: plateSetLayer.numRows,
  //     wells: wells
  //   });

  //   this.addLayer(layer);
  //   this.selectLayer(layer.id);
  // }

  getLayer(layerId) {
    return this._layers[layerId];
  }

  getLayerByIndex(layerIndex) {
    return this.layers[layerIndex];
  }

  removeLayer(layerId) {
    // If the layer being removed, is the currently selected layer, then
    // select a different layer before removing it.
    if (this.selectedLayer() && this.selectedLayer().id === layerId) {
      if (this.numLayers() == 1) {
        this.addEmptyLayer();
      }
      const nextLayer = this.layers.find((layer) => layer.id !== layerId);
      this.selectLayer(nextLayer.id);
    }

    delete this._layers[layerId];
  }

  updateLayerAttribute(layerId, field, value) {
    const layer = this.getLayer(layerId);
    console.log('updateLayerAttribute', layerId);
    console.log(this._layers);
    console.log(layer);
    layer.setState(field, value);
  }

  // Deprecated. Please use eventManager
  trigger(eventType) {
    this.eventListeners.forEach((listener) => {
      listener(eventType, {plate: this});
    });
  }

  // Deprecated. Please use eventManager
  registerListener(listener) {
    this.eventListeners.push(listener);
  }
}
