export default class ToolbarView {
  constructor(scope, state, controller) {
    this.scope = scope;
    this._state = state;
    this.controller = controller;
    this.root = jQuery(`<div class='toolbar'></div>`);
  }

  render() {
    this.root.empty();

    this._state.tools.forEach((tool) => {
      const selected = tool.selected ? 'selected' : '';
      this.root.append(jQuery(`<div class='item ${selected}' title='${tool.tooltip}' data-name='${tool.name}'><i class="fa-solid ${tool.iconCssClass}"></i></div>`));
    });

    return this.root;
  }

  initEvents() {
    this.root.on('click', '.item', (event) => {
      const element = jQuery(event.target).closest('.item');
      console.log('toolbarView.initEvents element: ', element);
      console.log(element.attr('class'));
      const name = element.attr('data-name');
      this.selectTool(name);
    });

    // Select the initial tool when we're first loading things
    setTimeout(() => {
      this.selectTool(this._state.tools[0].name);
    }, 500);
  }

  selectTool(toolName) {
    this.controller.trigger('tool/selected', {
      toolName: toolName
    });
  }

  setState(newState) {
    console.log('updating toolbarView state to', newState);
    this._state = newState;
    this.render();
  }
}
