import { newId } from './newId';
import Alphabet from './alphabet';
import Coordinate from './coordinate';

export default class Well {
  constructor(attrs = { id: null, rowIndex: null, colIndex: null, name: null, color: null, label: null, volumeAmount: null, volumeUnits: null, selected: null }) {
    this.state = {
      id: attrs.id || newId(),
      rowIndex: attrs.rowIndex,
      colIndex: attrs.colIndex,
      name: attrs.name || '',
      selected: attrs.selected || false,
      label: attrs.label || null,
      volumeAmount: attrs.volumeAmount || null,
      volumeUnits: attrs.volumeUnits || null,
      color: attrs.color || '#282c34'
    };

    this.parseName();
  }

  id() {
    return this.state.id;
  }

  deleteContents() {
    this.state.name = '';
    this.state.selected = false;
    this.state.label = '';
    this.state.volumeAmount = null;
    this.state.volumeUnits = null;
  }

  setState(key, value) {
    if (key == 'volumeAmount') {
      this.state[key] = parseFloat(value);
    } else {
      this.state[key] = value;
    }
  }

  getState(key) {
    return this.state[key];
  }

  get coordinate() {
    return new Coordinate({
      rowIndex: this.state.rowIndex,
      columnIndex: this.state.colIndex
    });
  }

  // After parsing this is the name of the item, with the volume and units removed.
  label() {
    return this.state.label;
  }

  // Full/original name
  name() {
    return this.state.name;
  }

  setName(newName) {
    this.setState('name', newName);
    this.parseName();
  }

  // TODO: Might need a more clear definition of empty...
  get isEmpty() {
    return (this.name() == '');
  }

  get hasContents() {
    return !this.isEmpty;
  }

  rowNum() {
    return this.state.rowIndex;
  }

  colNum() {
    return this.state.colIndex;
  }

  coordinateString() {
    return `${Alphabet.get(this.state.rowIndex)}${this.state.colIndex+1}`;
  }

  units() {
    return this.state.volumeUnits;
  }

  color() {
    return this.state.color;
  }

  amount() {
    return this.state.volumeAmount || 0.0;
  }

  isSelected() {
    return this.state.selected;
  }

  select() {
    this.state.selected = true;
  }

  deselect() {
    this.state.selected = false;
  }

  // Not yet implemented
  hasFlag(name, value) {
    return false;
  }

  /* Looks for names in the form "Ethanol 3.2mg/µL"
   * and pulls out the volume information.
   * It will put the pieces of info into the fields:
   *  - label = "Ethanol"
   *  - volumeAmount = 3.2
   *  - volumeUnits = 'mg/µL'
   * It only modifies those fields if they're currently null
   */
  parseName() {
    const result = Well.parseName(this.state.name);

    // If the value is set in `state` then leave it alone
    // Otherwise use the value from parsing (`result`)
    // Otherwise use a default
    this.state.label = this.state.label || result.label || this.state.name;
    this.state.volumeAmount = this.state.volumeAmount || result.volumeAmount || 1;
    this.state.volumeUnits = this.state.volumeUnits || result.volumeUnits || 'each';
  }

  static parseName(name) {
    /* For this regex, the string "ethanol 3.2mg/ul" results in the following match groups
     * 1: "ethanol"
     * 2: "3.2"
     * 3: "mg/ul"
     * 4: "mg/ul"
    */
    const regexp = /^([a-zA-Z].*?)\s+(\d+\.?\d*)\s*(([^\d\.]*)(\/([^\d\.]*))?)/;
    const matches = name.match(regexp);

    let result = {
      label: null,
      volumeAmount: null,
      volumeUnits: null
    };

    if (matches && matches.length > 0) {
      result = {
        label: matches[1],
        volumeAmount: parseFloat(matches[2]),
        volumeUnits: matches[3]
      };
    }

    return result;
  }

  static copy(sourceWell, destWell) {
    if (sourceWell && destWell) {
      destWell.setState('content', sourceWell.content);
      destWell.setState('name', sourceWell.name());
      destWell.setState('label', sourceWell.label());
      destWell.setState('amount', sourceWell.amount());
      destWell.setState('units', sourceWell.units());
      destWell.setState('color', sourceWell.color);
      destWell.setState('sourceWell', sourceWell.id());
    } else {
      console.log('Warning: Well.copy() had a blank sourceWell or destWell', sourceWell, destWell);
    }
  }
}
