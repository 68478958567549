import Layer from './layer';
import Well from './well';
import Papa from 'papaparse';

export default class CsvPlateSetFiller {
  constructor() {}

  static fill(plateSet, startingPlate, plateSetLayer, file, fileContents) {
    const result = Papa.parse(fileContents);
    const data = result.data;
    let started = false; // Don't start until we reach the startingPlate.
    let rowOffset = 0;
    plateSet.plates.forEach((plate) => {
      if (!started && plate.id() != startingPlate.id()) {
        alert('skipping plate' + plate.id());
        return;
      }
      started = true;
      CsvPlateSetFiller.fillPlate(plate, plateSetLayer, data, rowOffset);
      rowOffset += plate.numRows();
    });
  }

  static fillPlate(plate, plateSetLayer, data, rowOffset) {
    const wells = [];
    for (let rowIdx = 0; rowIdx < plate.numRows(); rowIdx++) {
      const fullRowIdx = rowIdx + rowOffset; // To account for being a later plate in the plateSet
      const row = [];
      const dataCells = fullRowIdx < data.length ? data[fullRowIdx] : [];
      for (let colIdx = 0; colIdx < plate.numCols(); colIdx++) {
        const element = colIdx < dataCells.length ? dataCells[colIdx] : '';
        const value = String(element).trim();
        let color = null;
        if (value && value != '') {
          const result = Well.parseName(value);
          const colorValue = result.label || value;
          color = plate.colorService.getColor(colorValue);
        }

        const well = new Well({
          name: value,
          color: color,
          rowIndex: rowIdx,
          colIndex: colIdx
        });
        row.push(well);
      }
      wells.push(row);
    }

    const layer = new Layer({
      id: plateSetLayer.id,
      name: plateSetLayer.name,
      numCols: plateSetLayer.numCols,
      numRows: plateSetLayer.numRows,
      wells: wells
    });

    plate.addLayer(layer);
    plate.selectLayer(layer.id);
  }
}