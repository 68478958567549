
export default class toolbarController {
  constructor(model, views, eventHandler) {
    this.toolbar = model;
    this.toolbarView = views.toolbar;
    this.platePanelView = views.platePanel;
    this.eventHandler = eventHandler;
  }

  get routes() {
    return {
      'tool/selected': this.selectTool.bind(this)
    };
  }

  trigger(eventType, data) {
    if (eventType in this.routes) {
      const action = this.routes[eventType];
      action(data);
    } else {
      console.log(`Warning: No actions are defined for '${eventType}' in toolbarController`);
    }
  }

  updateView() {
    this.toolbarView.setState(this.toolbar.state);
  }

  selectTool(eventType, data) {
    if (this.toolbar.selectedTool != null) {
      this.toolbar.selectedTool.deactivate(this.platePanelView);
    }

    const tool = this.toolbar.selectTool(data.toolName);
    tool.activate(this.platePanelView);
    this.updateView();
  }
}
