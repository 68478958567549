import interact from 'interactjs';

export default class ArrowTool {
  constructor(eventManager) {
    this.eventManager = eventManager;
    this.iconCssClass = 'fa-arrow-pointer';
    this.tooltip = 'Move and Resize';
    this.name = 'arrow';
    this._selected = false;
  }

  select() {
    this._selected = true;
  }

  deselect() {
    this._selected = false;
  }

  isSelected() {
    return this._selected;
  }

  toJson() {
    return {
      iconCssClass: this.iconCssClass,
      name: this.name,
      tooltip: this.tooltip,
      selected: this.isSelected()
    };
  }

  activate(view) {
    interact('.draggable').draggable(true);
    interact('.resizable').resizable(true);

    view.root.on('click', '.selectable', (event) => {
      event.stopPropagation();
      console.log('.selectable clicked on', event.target);
      const element = jQuery(event.target).closest('.selectable');
      const id = element.data('id');
      const type = element.data('type');
      if (typeof(id) == 'undefined' || typeof(type) == 'undefined') {
        console.log('Uh Oh! This is not supposed to be undefined.');
        debugger;
      }
      this.eventManager.trigger('item/select', {
        id: id,
        type: type
      });
    });
  }

  deactivate(view) {
    interact('.draggable').draggable(false);
    interact('.resizable').resizable(false);
    view.root.off('click', '.selectable');
  }
}
