export default class NewPlateModal {
  constructor(scope, state, eventManager) {
    this.scope = scope;
    this.state = state;
    this.eventManager = eventManager;
  }

  render() {
    return `
      <div class='newPlateForm'>
        <form onsubmit="return false">
          <select data-layout style='width: 16em;'>
            <option value='d1d1d1-default-guid'>Plate Layout</option>
            <option value='custom'>Custom size</option>
            <option value='d1d1d1-default-guid'>96 well: Standard</option>
            <option value='a1a1a1-a1a1a1-pretend-guid'>96 well: Controls on first & last column</option>
            <option value='b2b2b2-b2b2b2-pretend-guid'>96 well: Controls in the bottom corners</option>
            <option value='c1c1c1-pretend-guid'>384 well plate</option>
          </select>
          <span data-custom-size style='display:none'>
            <input type=number data-numCols value=12 style='width: 4em;'>
            <input type=number data-numRows value=8 style='width: 4em;'>
          </span>
          <button type="button" class='button-4' data-newPlateSetButton><i class="fa-regular fa-circle-plus"></i>&nbsp;New Plate Set</button>
        </form>
      </div>
    `;
  }

  initEvents() {
    this.scope.on('click', '[data-newPlateSetButton]', (event) => {
      const numRows = parseInt(jQuery('[data-numRows]').val());
      const numCols = parseInt(jQuery('[data-numCols]').val());
      const layoutId = jQuery('[data-layout]').val();
      const data = {
        numRows: numRows,
        numCols: numCols,
        layoutId: layoutId
      };

      this.eventManager.trigger('plateSet/add', data);
    });
    this.scope.on('change', '[data-layout]', (event) => {
      const selectElement = jQuery(event.target);
      if (selectElement.val() == 'custom') {
        this.scope.find('[data-custom-size]').show();
      } else {
        this.scope.find('[data-custom-size]').hide();
      }
    });
  }
}
