export default class ZoomTool {
  constructor(eventManager) {
    this.eventManager = eventManager;
    this.iconCssClass = 'fa-magnifying-glass';
    this.name = 'zoom';
    this.tooltip = 'Zoom';
    this._selected = false;
  }

  select() {
    this._selected = true;
  }

  deselect() {
    this._selected = false;
  }

  isSelected() {
    return this._selected;
  }

  toJson() {
    return {
      iconCssClass: this.iconCssClass,
      name: this.name,
      tooltip: this.tooltip,
      selected: this.isSelected()
    };
  }

  activate(view) {
    view.root.on('click', '.plate', (event) => {
      this.click(event);
    });
  }

  deactivate(view) {
    view.root.off('click', '.plate');
  }

  // When a plate is clicked
  click(event) {
    const element = jQuery(event.target).closest('.plateSet');
    const plateSetId = element.data('id');
    console.log('ZoomTool click', plateSetId);
    if (plateSetId != null) {
      const zoomAmount = event.altKey ? -0.2 : 0.2;
      this.eventManager.trigger('plateSet/zoom', {
        plateSetId: plateSetId,
        percent: zoomAmount
      });
    }
  }
}
