import { newId } from '../newId';
import Coordinate from '../coordinate';
import Layer from '../layer';
import Well from '../well';

/* This class allows users to define positions in a plate which can include or
   exclude different types of things. For example, positions can be reserved for controls.
   Things are allowed to be added by default.
   If there are multiple rules, the last rule for a position takes precedence.
*/
export default class PlateLayout {
  constructor(attrs = { id: null, name: null, numCols: null, numRows: null, defaultAllowed: null }) {
    this.state = {
      id: attrs.id || newId(),
      name: attrs.name || 'Unnamed Layout',
      numCols: attrs.numCols,
      numRows: attrs.numRows,
      defaultAllowed: attrs.defaultAllowed || true,
      rules: []
    };

    // PlateSetLayer {
    // constructor(attrs = { id: null, name: null, numCols: null, numRows: null }) {
    this._layer = new Layer({
      name: 'Plate Layout',
      numCols: this.state.numCols,
      numRows: this.state.numRows,
      layout: this
    });

    // Create wells in the layer so that we can display the layout
    this._layer.eachWell((well, rowIdx, colIdx) => {
      // const defaultLabel = this.defaultAllowed ? '' : 'X';
      const newWell = new Well({ name: '', label: '', rowIndex: rowIdx, colIndex: colIdx });
      this._layer.setWell(rowIdx, colIdx, newWell);
    });
    // this._layer.writeToLog();
  }

  get layer() {
    return this._layer;
  }

  get numCols() {
    return this.state.numCols;
  }

  get numRows() {
    return this.state.numRows;
  }

  // Specify a tag which is allowed to be positioned in the wells defined by
  // wellRange. See WellRange.parse() for supported formats.
  allow(wellRange, tag) {
    this.state.rules.push(
        {
          range: wellRange,
          tag: tag
        }
    );

    const coords = this.rangeToCoordinates(wellRange);
    coords.forEach((coord) => {
      const well = this._layer.get(coord);
      well.setName(tag);
    });
  }

  // Returns whether a given tag is allowed at the specified position
  allowed(rowIndex, colIndex, tag) {
    // console.log('.>. allowed?', rowIndex, colIndex, tag);
    // console.log('how many rules', this.state.rules.length);
    if (this.state.defaultAllowed) {
      return true;
    }

    let allowed = false;
    for (let i = 0; i < this.state.rules.length; i++) {
      const rule = this.state.rules[i];
      console.log('checking rule', i, rule);
      console.log('rule.tag == tag', rule.tag, tag);
      if (rule.tag == tag && this.rangeContains(rule.range, rowIndex, colIndex)) {
        allowed = true;
      }
    }

    return allowed;
  }

  rangeContains(wellRange, rowIndex, colIndex) {
    // console.log('range contains? wellRange', wellRange);
    const coords = this.rangeToCoordinates(wellRange);
    // console.log(coords);
    for (let i = 0; i < coords.length; i++) {
      const coord = coords[i];
      // console.log('rangeContains?', coord.rowIndex, rowIndex, coord.columnIndex, colIndex);
      if (coord.rowIndex == rowIndex && coord.columnIndex == colIndex) {
        // console.log('yes range contains');
        return true;
      }
    }
    // console.log('no range contains');
    return false;
  }

  // Input:
  // e.g. "A2; C6:D7; E"
  // The output is essentially [A2, C6, C7, D6, D7, E1, E2, E3, ..., E8],
  // except it's converted to an array of Coordinates
  // e.g. [new Coordinate(row=2, col=0), ... ]
  parseWellRanges(wellRangeString) {
    const wellRanges = wellRangeString.split(';');
    let coordinates = [];
    wellRanges.forEach((range) => {
      coordinates = coordinates.concat(this.rangeToCoordinates(range));
    });

    return coordinates;
  }

  // Input: A string representing a well, or a set of wells
  // e.g. "A2", or "A2:C4"
  // "A2" refers to the well at A2
  // "A2:C4" refers to the grid between the two positions
  //    (e.g. A2, B2, C2, A3, B3, C3, A4, B4, C4)
  // Output:
  // An array that includes all of the wells included in the range,
  // using zero-based well indexes
  // e.g.
  // A1 => new Coordinate(row=0, col=0)
  // B2 => new Coordinate(row=1, col=1)
  rangeToCoordinates(range) {
    console.log('rangeToCoordinates, range', range);
    let matches = null;
    const rangeRegex = /([A-Z]\d+):([A-Z]\d+)/gi;
    matches = rangeRegex.exec(range);
    if (matches && matches.length > 1) {
      const startCoord = Coordinate.parse(matches[1]);
      const stopCoord = Coordinate.parse(matches[2]);
      const result = [];
      // console.log('startCoord', startCoord);
      // console.log('stopCoord', stopCoord);
      this.iterateRange(startCoord, stopCoord, (currentCoordinate) => {
        result.push(currentCoordinate);
      });
      return result;
    }
    const singleRegex = /([A-Z]\d+)/gi;
    matches = singleRegex.exec(range);
    if (matches && matches.length > 0) {
      return [Coordinate.parse(matches[1])];
    } else {
      throw new Error(`Invalid range format: ${range}`);
    }
  }

  // Given a range like "A2:C4" iterate over the grid between the two positions
  // "A2:C4" => A2, B2, C2, A3, B3, C3, A4, B4, C4
  // It expects input in the form of a start and stop Coordinate
  // As it iterates it will call your function and give you another
  // Coordinate object for each position.
  iterateRange(startCoord, stopCoord, func) {
    const startRow = Math.max(Math.min(startCoord.rowIndex, stopCoord.rowIndex), 0);
    const startCol = Math.max(Math.min(startCoord.columnIndex, stopCoord.columnIndex), 0);
    const maxRow = Math.min(Math.max(startCoord.rowIndex, stopCoord.rowIndex), this.state.numRows - 1);
    const maxCol = Math.min(Math.max(startCoord.columnIndex, stopCoord.columnIndex), this.state.numCols - 1);

    // console.log('startRow', 'startCol', startRow, startCol);
    // console.log('maxRow', 'maxCol', maxRow, maxCol);

    for (let row = startRow; row <= maxRow; row++) {
      for (let col = startCol; col <= maxCol; col++) {
        const currCoord = new Coordinate({ rowIndex: row, columnIndex: col });
        func.call(this, currCoord);
      }
    }
  }

  prohibitAll() {
    this.state.defaultAllowed = false;
  }

  /* This is a dummy function, pretending that we have a real way to get plate layouts
     from a database or an API or something.
     e.g. PlateLayout.find(37)
  */
  static fakeFind(guid) {
    if (guid == 'a1a1a1-a1a1a1-pretend-guid') {
      const layout = new PlateLayout({
        id: guid,
        name: 'L1 - controls on first & last column',
        numCols: 12,
        numRows: 8
      });
      layout.prohibitAll();
      layout.allow('A1:H1', 'controls');
      layout.allow('A2:H11', 'samples');
      layout.allow('A12:H12', 'controls');
      return layout;
    } else if (guid == 'b2b2b2-b2b2b2-pretend-guid') {
      const layout = new PlateLayout({
        id: guid,
        name: 'L2 - controls in the corners',
        numCols: 12,
        numRows: 8
      });
      layout.prohibitAll();
      layout.allow('G1:H4', 'controls');
      layout.allow('G9:H12', 'controls');
      layout.allow('A1:F12', 'samples');
      layout.allow('G5:H8', 'samples');
      return layout;
    } else if (guid == 'c1c1c1-pretend-guid') {
      const layout = new PlateLayout({
        id: guid,
        name: '384 well plate',
        numCols: 24,
        numRows: 16
      });
      return layout;
    } else if (guid == 'd1d1d1-default-guid') {
      const layout = new PlateLayout({
        id: guid,
        name: '96 well plate',
        numCols: 12,
        numRows: 8
      });
      return layout;
    }
  }
}
