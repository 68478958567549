
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp, initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyAZgSG7VwJaANx0B5juwh35AdeF8lLztec',
  authDomain: 'scimed-lab.web.app',
  // authDomain: 'scimed-lab.firebaseapp.com',
  projectId: 'scimed-lab',
  storageBucket: 'scimed-lab.appspot.com',
  messagingSenderId: '586346287047',
  appId: '1:586346287047:web:529ab43aa1781e85ac93e2',
  measurementId: 'G-XZGKP5BEPY'
};
initializeApp(firebaseConfig);
const functions = getFunctions(getApp());
const openplates = httpsCallable(functions, 'openplates');
const saveplate = httpsCallable(functions, 'saveplate');

export default class PlateApi {
  static listPlates(data, callbacks) {
    console.log('listPlates()');
    openplates(data)
        .then((result) => {
          console.log('result', result);
          callbacks.success(result);
        });
  }

  static save(data) {
    console.log('save() with ', data);
    this.validate(data);
    console.log('save() 2 with ', data);
    saveplate(data)
        .then((result) => {
          console.log('result', result);
        });
  }

  // Firestore doesn't support an array of arrays.
  // This function checks for those and gives us more information about where it is
  // more eaily than reviewing the Firebase function logs
  static validate(data, hierarchy = [], levelName = 'root', parentWasArray = false) {
    hierarchy.push(levelName);

    if (this.isObject(data)) {
      // digg in
      Object.keys(data).forEach((key) => {
        this.validate(data[key], hierarchy, key, false);
      });
    } else if (this.isArray(data)) {
      if (parentWasArray) {
        throw new Error(`Found an array of arrays at: ${hierarchy.join(' => ')}`);
      }
      data.forEach((item, index) => {
        this.validate(item, hierarchy, `Array index ${index}`, true);
      });
    } else if (this.isNull(data)) {
      return true;
    } else {
      // Values like strings, ints, floats, etc.
      return true;
    }

    return true;
  }

  static isObject(x) {
    return (
      typeof x === 'object' &&
      !PlateApi.isArray(x) &&
      !PlateApi.isNull(x)
    );
  }

  static isArray(x) {
    return Array.isArray(x);
  }

  static isNull(x) {
    return x === null;
  }
}
