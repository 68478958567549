export default class MenubarView {
  constructor(scope, state, controller) {
    this.scope = scope;
    this.state = state;
    this.controller = controller;
    this.root = jQuery(`<div class='menubar'></div>`);
  }

  render() {
    const items = jQuery(`
      <div class='item'>
        <label>File</label>
        <div class='content'>
          <div class='item' data-command='open'>Open...</div>
          <div class='item' data-command='save'><i class="fa-regular fa-floppy-disk"></i>Save</div>
        </div>
      </div>
      <div class='item'>
        <label>Edit</label>
        <div class='content'>
          <div class='item' data-command='copy'>Copy</div>
          <div class='item' data-command='paste'>Paste</div>
        </div>
      </div>
      <div class='item'>
        <label>Mapping</label>
        <div class='content'>
          <div class='item' data-command='map-plates'>Map Plates...</div>
        </div>
      </div>
      <div class='item' data-command='login'>
        Login
      </div>
      <div class='item' data-command='logout'>
        Logout
      </div>
    `);

    this.root.append(items);
    return this.root;
  }

  initEvents() {
    const handlers = {
      'open': (event) => {
        this.controller.trigger('app/open', {}, event);
      },
      'save': (event) => {
        this.controller.trigger('app/save', {}, event);
      },
      'copy': (event) => {
        this.controller.trigger('app/copy', {}, event);
      },
      'paste': (event) => {
        this.controller.trigger('app/paste', {}, event);
      },
      'login': (event) => {
        this.controller.trigger('app/login', {}, event);
      },
      'logout': (event) => {
        this.controller.trigger('app/logout', {}, event);
      },
      'map-plates': (event) => {
        this.controller.trigger('plate/mapping', {}, event);
      }
    };

    this.root.on('click', '[data-command]', (event) => {
      const target = jQuery(event.target).closest('.item');
      const action = target.attr('data-command');
      handlers[action](event);
    });
  }
}
