<div class='plateMapping window'>
  <div class='header'>Map Plates</div>
  <div class="content">
    <section class='plateLayout' data-section="source">
      <div class='header'>Source Plates</div>
      <div class="formRow">
        <label>Plate Set:</label>
        <select name="plateSet" id="mappingSourcePlateSetId"></select>
      </div>
      <div class="formRow">
        <label>Move By:</label>
        <select name="axis" style="width: 7em;">
          <option value='row'>Row</option>
          <option value='col'>Column</option>
        </select>
        <br>
      </div>
      <div class="formRow">
        <label>Wrap At:</label>
        <input name="wrapAt" placeholder="full distance" style="width: 7em;">
      </div>
      <div class="formRow">
        <label>Direction:</label>
        <select name="horizontalDirection" style="width: 7em;">
          <option value="right">left to right</option>
          <option value="left">right to left</option>
        </select>&nbsp;
        <select name="verticalDirection">
          <option value="down">top to bottom</option>
          <option value="up">bottom to top</option>
        </select>
      </div>
    </section>
    <section class='plateLayout' data-section="destination">
      <div class='header'>Destination Plates</div>
      <div class="formRow">
        <label>Plate Set:</label>
        <select name="plateSet" id="mappingDestinationPlateSetId"></select>
      </div>
      <div class="formRow">
        <label>Move By:</label>
        <select name="axis" style="width: 7em;">
          <option value='row'>Row</option>
          <option value='col'>Column</option>
        </select>
        <br>
      </div>
      <div class="formRow">
        <label>Wrap At:</label>
        <input name="wrapAt" placeholder="full distance" style="width: 7em;">
      </div>
      <div class="formRow">
        <label>Direction:</label>
        <select name="horizontalDirection" style="width: 7em;">
          <option value="right">left to right</option>
          <option value="left">right to left</option>
        </select>&nbsp;
        <select name="verticalDirection">
          <option value="down">top to bottom</option>
          <option value="up">bottom to top</option>
        </select>
      </div>
    </section>
    <section class='repeatDefinition' data-section="repeatDefinition">
      <div class='header'>Repeat</div>
      <div class="formRow">
        <label>Repeat:</label>
        <input name="repeatCount" type="number" min="0" max="1000" value="1" >
      </div>
      <div class="formRow">
        <label>Skip:</label>
        <input name="repeatSkip" type="number" min="0" max="1000" value="0">
      </div>
    </section>
    <div class="footer">
      <button data-close>Apply</button>&nbsp;
      <button data-close>Cancel</button>
    </div>
  </div>
</div>