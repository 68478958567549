
// Draw an SVG line or arrow connecting two points in the UI
export default class Connector {
  constructor(attrs = {}) {
    this.start = attrs.start;
    this.end = attrs.end;
    this.scope = attrs.scope;
    this.color = attrs.color || 'red';
    this.root = this.createCanvas();

    this.startDirection = this.start.direction || 'horizontal';
    this.endDirection = this.end.direction || 'horizontal';
  }

  createCanvas() {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    // svg.setAttribute('id', 'svg-canvas');
    svg.setAttribute('class', 'myArrowClass');
    svg.setAttribute('style', 'position: absolute; top: 0px; left: 0px;');
    svg.setAttribute('width', this.scope.width());
    svg.setAttribute('height', this.scope.height());
    svg.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:xlink', 'http://www.w3.org/1999/xlink');
    return svg;
  }

  moveEnd(newEnd) {
    this.end = newEnd;
    this.endDirection = newEnd.direction;
    this.render();
  }

  render() {
    this.root.innerHTML = '';
    const arrow = this.createArrow();
    this.root.append(arrow);

    const line = this.drawCurvedLine(this.start.x, this.start.y, this.end.x, this.end.y, this.color, 0.2);
    this.root.append(line);

    return this.root;
  }

  destroy() {
    this.root.innerHTML = '';
  }

  createArrow() {
    const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');

    const marker = document.createElementNS('http://www.w3.org/2000/svg', 'marker');
    marker.setAttribute('id', 'triangle');
    marker.setAttribute('viewBox', '0 0 10 10');
    marker.setAttribute('refX', '0');
    marker.setAttribute('refY', '5');
    marker.setAttribute('markerUnits', 'strokeWidth');
    marker.setAttribute('markerWidth', '7');
    marker.setAttribute('markerHeight', '5');
    marker.setAttribute('orient', 'auto');
    marker.setAttribute('stroke', this.color);
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    marker.appendChild(path);
    path.setAttribute('d', 'M 0 0 L 10 5 L 0 10 z');
    defs.appendChild(marker);

    return defs;
  }

  /* (x1, y1) is the actual start point
   * (x2, y2) is the actual end point
   * (hx1, hy1) is a point a that is horiztonally or vertically a few pixels away
   *   from the start point, that gives it a bit of a straight line before it starts curving
   *   the distance of that small horizontal or vertical line is `delta`
   * (hx2, hy2) is the same little horizontal or vertical line, but it's from the end point
   */
  drawCurvedLine(x1, y1, x2, y2, color, tension) {
    const shape = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    const delta = (x2 - x1) * tension;
    let hx1 = x1;
    let hy1 = y1;
    let hx2 = x2;
    let hy2 = y2;

    if (this.startDirection == 'horizontal') {
      hx1 += delta;
    } else if (this.startDirection == 'vertical') {
      hy1 += delta;
    }

    if (this.endDirection == 'horizontal') {
      // When the end is horizontal, we subtract a bit from x2 so that we make space for the width of the arrow
      x2 -= 18;
      //
      hx2 = x2 - delta;
    } else if (this.endDirection == 'vertical') {
      // When the end is vertical, we subtract a bit from y2 to make space for the height of the arrow
      y2 -= 18;
      //
      hy2 = y2 - delta;
    }

    const path = `M ${x1} ${y1} C ${hx1} ${hy1} ${hx2} ${hy2} ${x2} ${y2}`;
    shape.setAttributeNS(null, 'd', path);
    shape.setAttributeNS(null, 'fill', 'none');
    shape.setAttributeNS(null, 'stroke', color);
    shape.setAttributeNS(null, 'stroke-width', 3);
    shape.setAttributeNS(null, 'marker-end', 'url(#triangle)');

    return shape;
  }

  static getPosition(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }
}
