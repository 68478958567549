// import LeftToRightIterator from './leftToRightIterator.js';
// import RightToLeftIterator from './rightToLeftIterator.js';
// import PlateIterator from './plateIterator.js';
import LayerIterator from './layerIterator.js';

export default class IteratorFactory {
  static fromMovementDefinition(movementDefinition, layer, options = {}) {
    // return new PlateIterator(plateSet, movementDefinition, options);
    return new LayerIterator(layer, movementDefinition, options);
  }
}
