export default class Range {
  constructor(min = null, max = null) {
    this._min = min;
    this._max = max;
  }

  min() {
    return this._min;
  }

  setMin(value) {
    this._min = value;
  }

  max() {
    return this._max;
  }

  setMax(value) {
    this._max = value;
  }

  // If this value is not currently in the range, we will expand the min and max
  // as needed so that this is included in the range.
  expandRange(value) {
    if (this.min() == null || value < this.min()) {
      this.setMin(value);
    }
    if (this.max() == null || value > this.max()) {
      this.setMax(value);
    }
  }

  // NOTE! This returns a number between 0 and 1.
  // For example, 50% is represented as 0.5
  //
  // Example:
  // let range = new Range(400, 500);
  // range.calculatePercentage(425) => 0.25
  calculatePercentage(value) {
    return (value - this.min()) / (this.max() - this.min());
  }

  array() {
    if (this.min() == null || this.max() == null) {
      return [];
    }

    const array = [];
    for (let i = this.min(); i <= this.max(); i++) {
      array.push(i);
    }
    return array;
  }

  includes(value) {
    return this.array().includes(value);
  }
}
