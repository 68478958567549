import { initializeApp } from 'firebase/app';
import { getAuth, signInWithRedirect, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';

/*
* IMPORTANT
* If you change domain names you have to go to the apis/credentials
* page for this project in the Google Cloud Console
* And update the authortized redirect URIs
* https://console.cloud.google.com/apis/credentials?project=scimed-lab
*/
export default class Authentication {
  // // FirebaseUI config.
  // static uiConfig = {
  //   signInSuccessUrl: '<url-to-redirect-to-on-success>',
  //   signInOptions: [
  //     firebase.auth.GoogleAuthProvider.PROVIDER_ID
  //   ],
  //   // tosUrl and privacyPolicyUrl accept either url string or a callback
  //   // function.
  //   // Terms of service url/callback.
  //   tosUrl: '<your-tos-url>',
  //   // Privacy policy url/callback.
  //   privacyPolicyUrl: function() {
  //     window.location.assign('<your-privacy-policy-url>');
  //   }
  // };

  static get firebaseConfig() {
    return {
      apiKey: 'AIzaSyAZgSG7VwJaANx0B5juwh35AdeF8lLztec',
      authDomain: 'scimed-lab.web.app',
      // authDomain: 'scimed-lab.firebaseapp.com',
      projectId: 'scimed-lab',
      storageBucket: 'scimed-lab.appspot.com',
      messagingSenderId: '586346287047',
      appId: '1:586346287047:web:529ab43aa1781e85ac93e2',
      measurementId: 'G-XZGKP5BEPY'
    };
  }

  static init() {
    const firebaseApp = initializeApp(Authentication.firebaseConfig);

    const auth = getAuth(firebaseApp);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        console.log('Authentication', 'logged in as ', uid);
        // ...
      } else {
        // User is signed out
        // ...
        console.log('Authentication', 'User is signed out');
      }
    });
  }

  static login() {
    const firebaseApp = initializeApp(Authentication.firebaseConfig);
    const auth = getAuth(firebaseApp);
    const provider = new GoogleAuthProvider();

    // https://scimed-lab.web.app/__/auth/handler
    signInWithRedirect(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.

          // const credential = GoogleAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;

          const user = result.user;
          // IdP data available using getAdditionalUserInfo(result)
          console.log('logged in', user);
        }).catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const email = error.customData.email;
          // const credential = GoogleAuthProvider.credentialFromError(error);
          console.log('error', errorCode, errorMessage, email);
        });

    onAuthStateChanged(auth, (user) => {
      console.log('onAuthStateChanged', auth, user);
      // Check for user status
    });
  }

  static logout() {
    const firebaseApp = initializeApp(Authentication.firebaseConfig);
    const auth = getAuth(firebaseApp);
    auth.signOut();
  }
}
