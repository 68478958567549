import { newId } from './newId';
import Well from './well';
import PlateLayout from './plate/plateLayout';
import Coordinate from './coordinate';

export default class Layer {
  constructor(attrs = { id: null, name: null, numCols: null, numRows: null, wells: null, selected: null, visible: null, mappingId: null, layout: null }) {
    this.state = {
      id: attrs.id || newId(),
      mappingId: attrs.mappingId,
      name: attrs.name,
      numCols: attrs.numCols || 12,
      numRows: attrs.numRows || 8,
      rows: attrs.wells,
      selected: attrs.selected || false,
      visible: attrs.visible || true,
      layout: attrs.layout || new PlateLayout() // Can we remove this?
    };

    // These depend on attributes set earlier
    this.state = {
      ...this.state,
      name: this.state.name || `Layer ${this.state.id}`
    };

    this.createEmptyWells();
  }

  getState(key) {
    return this.state[key];
  }

  setState(key, value) {
    this.state[key] = value;
  }

  get numRows() {
    return this.state.numRows;
  }

  get numCols() {
    return this.state.numCols;
  }

  get name() {
    return this.state.name;
  }

  get id() {
    return this.state.id;
  }

  get order() {
    return this.state.order;
  }

  set order(value) {
    this.state.order = value;
  }

  get mappingId() {
    return this.state.mappingId;
  }

  get layout() {
    return this.state.layout;
  }

  isSelected() {
    return this.state.selected;
  }

  select() {
    this.state.selected = true;
  }

  deselect() {
    this.state.selected = false;
  }

  toggleVisiblity() {
    this.state.visible = !this.state.visible;
  }

  show() {
    this.state.visible = true;
  }

  hide() {
    this.state.visible = false;
  }

  visible() {
    return this.state.visible;
  }

  wellMatrix() {
    return this.state.rows;
  }

  hasSelectedWells() {
    return (this.selectedWells().length > 0);
  }

  selectWells(wellIds, options = {deslectOthers: true}) {
    console.log('layer.selectWells()', wellIds);
    this.eachWell((well) => {
      if (wellIds.includes(well.id())) {
        well.select();
      } else if (options.deselectOthers) {
        well.deselect();
      }
    });
  }

  deselectWells() {
    this.eachWell((well) => {
      well.deselect();
    });
  }

  selectedWells() {
    const wells = [];
    this.eachWell((well) => {
      if (well.isSelected()) {
        wells.push(well);
      }
    });
    return wells;
  }

  writeToLog() {
    console.log(`==> Layer: ${this.state.name}, ${this.state.id} <==`);
    this.eachRow((row) => {
      const rowLabels = row.map((well) => {
        return well.label();
      });
      console.log(rowLabels.join(', '));
    });
    console.log('========================');
  }

  // Convert matrix to an array of wells
  wells() {
    const wells = [];
    this.eachWell((well) => {
      wells.push(well);
    });
    return wells;
  }

  // Annoyingly `typeof NaN === 'number'`
  isNumber(x) {
    return typeof x === 'number' && !isNaN(x);
  }

  isNotANumber(x) {
    return !this.isNumber(x);
  }

  allowsTag(coordinate, tag) {
    return this.layout.allowed(coordinate.rowIndex, coordinate.columnIndex, tag);
  }

  get(coordinate) {
    const rowIndex = coordinate.rowIndex;
    const colIndex = coordinate.columnIndex;
    if (this.isNotANumber(rowIndex) || rowIndex < 0 || rowIndex >= this.state.rows.length) {
      alert(`layer.get() rowIndex is out of bounds. ${rowIndex} is outside of 0 - ${this.state.rows.length - 1}`);
      alert(typeof rowIndex);
      debugger;
    }
    // console.log('>>>>', rowIndex, this.state.rows[rowIndex]);
    try {
      if (this.isNotANumber(colIndex) || colIndex < 0 || colIndex >= this.state.rows[rowIndex].length) {
        alert(`layer.get() colIndex is out of bounds. ${colIndex} is outside of 0 - ${this.state.rows[rowIndex].length - 1}`);
        debugger;
      }
    } catch (e) {
      alert('whoops');
      debugger;
    }
    return this.state.rows[rowIndex][colIndex];
  }

  setWell(rowIndex, colIndex, sourceWell) {
    const coordinate = new Coordinate({ rowIndex: rowIndex, columnIndex: colIndex });
    const destWell = this.get(coordinate);
    Well.copy(sourceWell, destWell);
  }

  // First copy those whole matrix into a new matrix
  // Then trim empty columns and rows from the
  // top, bottom, left, and right
  selectedWellsMatrix() {
    const newRows = [];
    this.eachRow((row) => {
      const newRow = [];
      row.forEach((well) => {
        if (well.isSelected()) {
          newRow.push(well);
        } else {
          newRow.push(null);
        }
      });
      newRows.push(newRow);
    });

    // Next we want to trim rows and columns that are blank
    while (newRows.length > 0 && Layer.isEmptyRow(newRows[0])) {
      newRows.shift();
    }

    while (newRows.length > 0 && Layer.isEmptyRow(newRows[newRows.length - 1])) {
      newRows.pop();
    }

    while (newRows.length > 0 && Layer.isEmptyCol(newRows, 0)) {
      // remove first column
      newRows.forEach((row) => row.shift());
    }

    while (newRows.length > 0 && Layer.isEmptyCol(newRows, newRows[0].length - 1)) {
      // remove last column
      newRows.forEach((row) => row.pop());
    }

    return newRows;
  }

  static isEmptyRow(arr) {
    return arr.every((element) => {
      return (element == null);
    });
  }

  static isEmptyCol(matrix, colIndex) {
    return matrix.every((row) => {
      return (row[colIndex] == null);
    });
  }

  eachRow(func) {
    for (let rowIdx = 0; rowIdx < this.numRows; rowIdx++) {
      func.call(this, this.state.rows[rowIdx]);
    }
  }

  showInLog(extraInfo) {
    console.log(`>>>> Layer <<<<`);
    console.log(extraInfo);
    console.log(this.state);
    this.eachRow((row) => {
      const wellNames = row.map((well) => {
        return `${well.name()}:${well.label()}:${well.amount()}`;
      });
      console.log(wellNames);
    });
    console.log(`======================`);
  }

  each(func) {
    alert('please replace layer.each() with layer.eachWell()');
    // eslint-disable-next-line no-debugger
    debugger;
    for (let rowIdx = 0; rowIdx < this.numRows; rowIdx++) {
      for (let colIdx = 0; colIdx < this.numCols; colIdx++) {
        func.call(this, this.state.rows[rowIdx][colIdx], rowIdx, colIdx);
      }
    }
  }

  eachWell(func) {
    for (let rowIdx = 0; rowIdx < this.numRows; rowIdx++) {
      for (let colIdx = 0; colIdx < this.numCols; colIdx++) {
        func.call(this, this.state.rows[rowIdx][colIdx], rowIdx, colIdx);
      }
    }
  }

  // iterator
  eachSelected(func) {
    this.selectedWells().forEach(func);
  }

  createEmptyWells() {
    // Fill the plate with a default set of wells if it's empty
    if (this.state.rows == null) {
      this.state.rows = Array(this.numRows);

      for (let rowNum = 0; rowNum < this.numRows; rowNum++) {
        this.state.rows[rowNum] = Array(this.numCols);
      }

      for (let rowIdx = 0; rowIdx < this.numRows; rowIdx++) {
        for (let colIdx = 0; colIdx < this.numCols; colIdx++) {
          // const name = `${rowIdx+1}, ${colIdx+1}`;
          this.state.rows[rowIdx][colIdx] = new Well({
            name: '',
            rowIndex: rowIdx,
            colIndex: colIdx
          });
        }
      }
    }
  }

  toJson() {
    const data = {...this.state};
    console.log('layer1', data);
    // Firestore doesn't support data that is in the format array of arrays
    // So we convert this.state.rows to a different format for storage

    // Convert `rows` array into `storedRows` which is a hash where the keys
    // are integers (using the row indexes)
    data.storedRows = {};
    data.rows.forEach((row, rowIndex) => {
      data.storedRows[rowIndex] = row;
    });
    data.rows = null;
    console.log('layer2', data);
    return data;
  }

  static fromJson(newState) {
    const rows = [];
    for (let rowIndex = 0; rowIndex < newState.numRows; rowIndex++) {
      const storedRow = newState.storedRows[rowIndex];
      const row = [];
      storedRow.forEach((wellData) => {
        row.push(new Well(wellData.state));
      });
      console.log('new row', row);
      rows.push(row);
    }
    newState['wells'] = rows;

    return new Layer(newState);
  }
}
