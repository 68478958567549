import { newId } from '../newId';
import Plate from '../plate.js';
import PlateLayout from './plateLayout.js';
import Layer from '../layer.js';
import PlateSetLayer from './plateSetLayer.js';

/*
 * What's the difference between an existing plate set with things already set,
 * and a new plate set where new positions and contents can be added?
 */
export default class PlateSet {
  constructor(attrs = { layoutId: null, layout: null, plates: null }) {
    const values = attrs;
    values.id = attrs.id || newId();
    values.defaultWidth = attrs.defaultWidth || 448;
    values.width = attrs.width || values.defaultWidth;

    if (attrs.layoutId != null) {
      values.layoutId = attrs.layoutId;
      this.layout = this.findLayout(values.layoutId);
    } else if (attrs.layout != null) {
      this.layout = attrs.layout;
    } else {
      throw new Error('PlateSet requires a `layout` or `layoutId`.');
    }

    const newLayerId = newId();
    values.layers = attrs.layers || [
      new PlateSetLayer({
        id: newLayerId,
        name: `Layer ${newLayerId}`,
        numCols: this.layout.numCols,
        numRows: this.layout.numRows
      })
    ];


    this.state = values;

    this.plates = values.plates;
    if (this.plates == null) {
      this.plates = [];
      this.createPlate();
    }
  }

  get default_num_cols() {
    return this.numCols || 12;
  }

  get default_num_rows() {
    return this.numRows || 8;
  }

  get layoutId() {
    return this.state.layoutId;
  }

  static ensurePlateHasAllLayers(plate, plateSetLayers, numCols, numRows) {
    console.log('ensurePlateHasAllLayers', plateSetLayers.map((x) => x.id));
    console.log('plate', plate.id());
    plateSetLayers.forEach((plateSetLayer) => {
      const layer = plate.layers.find((layer) => layer.id == plateSetLayer.id);
      if (layer) {
        console.log('Found', plateSetLayer.id);
      } else {
        console.log('Adding', plateSetLayer.id);
        plate.addLayer(new Layer({
          id: plateSetLayer.id,
          name: plateSetLayer.name,
          numCols: numCols,
          numRows: numRows
        }));
      }
    });
  }

  id() {
    return this.state.id;
  }

  onScreenName() {
    return this.id();
  }

  size() {
    if (this.plates === undefined) {
      return 0;
    }

    return this.plates.length;
  }

  numPlates() {
    return this.size();
  }

  any() {
    return this.size() > 0;
  }

  none() {
    return this.size <= 0;
  }

  addPlate(plate) {
    this.plates.push(plate);
    PlateSet.ensurePlateHasAllLayers(plate, this.state.layers, this.numCols, this.numRows);
  }

  removePlate(plate) {
    const index = this.plates.indexOf(plate);
    this.plates.splice(index, 1);
  }

  getPlate(plateIndex) {
    return this.plates[plateIndex];
  }

  getPlateById(plateId) {
    return this.plates.find((plate) => {
      if (plate.id() === plateId) {
        return true;
      }
    });
  }

  hasPlateId(plateId) {
    let result = false;

    this.each((plate) => {
      console.log('hasPlateId, ', plateId, plate.id());
      if (plate.id() == plateId) {
        result = true;
      }
    });

    console.log('hasPlateId: ', result);
    return result;
  }

  // The number of columns in each plate in this PlateSet
  get numCols() {
    return this.layout.numCols;
  }

  // The number of rows in each plate in this PlateSet
  get numRows() {
    return this.layout.numRows;
  }

  zoom(attrs = { percent: 0.2 }) {
    // const height = this.getState('height');
    const width = this.getState('width');
    // this.setState('height', height + (this.defaultHeight * attrs.percent));
    this.setState('width', width + (this.state.defaultWidth * attrs.percent));
  }

  select() {
    this.setState('selected', true);
  }

  getState(key) {
    return this.state[key];
  }

  setState(key, value) {
    this.state[key] = value;
  }

  deselect() {
    this.setState('selected', false);
  }

  isSelected() {
    return this.state.selected;
  }

  selectWells(wellIds, options = {deslectOthers: true}) {
    this.plates.forEach((plate) => {
      plate.selectWells(wellIds, options);
      // debugger;
    });
  }

  deselectWells() {
    this.plates.forEach((plate) => {
      plate.deselectWells();
    });
  }

  selectedWells() {
    const wells = [];
    this.plates.forEach((plate) => {
      wells.push(plate.selectedWells());
    });
  }

  createPlate() {
    const plate = new Plate({
      numCols: this.layout.numCols,
      numRows: this.layout.numRows,
      layout: this.layout
    });

    const plateNum = this.size();
    console.log('createPlate', plateNum);
    this.addPlate(plate);
    return plate;
  }

  findOrCreatePlateByIndex(plateIndex) {
    let plate = this.getPlate(plateIndex);
    if (plate == null) {
      plate = this.createPlate();
    }
    return plate;
  }

  selectColumn(colIndex) {
    this.plates.forEach((plate) => {
      plate.each((well) => {
        if (well.colIndex === colIndex) {
          well.toggleFlag('skip');
        }
      });
    });
  }

  selectRow(rowIndex) {
    this.plates.forEach((plate) => {
      plate.each((well) => {
        if (well.rowIndex === rowIndex) {
          well.toggleFlag('skip');
        }
      });
    });
  }

  // When deleting wells, it currently only works on the selectedLayer
  // we may want other options like "visible wells", or all layers?
  deleteSelected(selection) {
    // selection = {
    //   type: type,
    //   parent: parent,
    //   selectedItems: items
    // };
    if (selection.type == 'well') {
      let selectedWells = [];
      const selectedWellIds = selection.selectedItems;
      this.plates.forEach((plate) => {
        const matchingWells = plate.selectedLayer().wells().filter((well) => {
          return selectedWellIds.includes(well.id());
        });
        selectedWells = selectedWells.concat(matchingWells);
      });

      selectedWells.forEach((well) => {
        well.deleteContents();
      });
    }
  }

  // // For efficiency it would be nice to add some caching, so that we don't
  // // have to look up the plate layout each time this is called.
  // get layout() {
  //   let layout = this.findLayout(this.state.layoutId);

  //   if (layout == null) {
  //     layout = new PlateLayout({ numCols: this.default_num_cols, numRows: this.default_num_rows});
  //   }

  //   return layout;
  // }

  findLayout(layoutId) {
    return PlateLayout.fakeFind(layoutId);
  }

  // Each Plate
  each(func) {
    console.log('plateSet.each()');
    for (let i = 0; i < this.plates.length; i++) {
      func.call(this, this.plates[i]);
    }
  }

  // Iterate over each well in this plateSet,
  // for the given layerId
  eachWell(layerId, func) {
    console.log('pS', this.id());
    for (let plateIndex = 0; plateIndex < this.plates.length; plateIndex++) {
      const plate = this.plates[plateIndex];
      const layer = plate.getLayer(layerId);
      console.log('plateSet.eachWell() : layer', layer);
      if (!layer) {
        console.log('layer is null. We will just run the call back with null for each well');
        for (let colIndex = 0; colIndex < plate.numCols(); colIndex++) {
          for (let rowIndex = 0; rowIndex < plate.numRows(); rowIndex++) {
            func.call(this, null, rowIndex, colIndex);
          }
        }
      }
      layer.eachWell((well) => {
        const rowIndex = well.rowNum() + (plate.numRows() * plateIndex);
        const colIndex = well.colNum();
        func.call(this, well, rowIndex, colIndex);
      });
    }
  }

  // This does assume that all of the plates in the set are the same size
  numWells() {
    return (this.size() * (this.numRows * this.numCols));
  }

  map(callbackFunction) {
    const result = [];
    const length = this.size();
    for (let i = 0; i < length; i++) {
      const counter = callbackFunction(this.plates[i]);
      result.push(counter);
    }
    return result;
  }

  print() {
    this.plates.forEach((plate) => {
      plate.print();
      console.log('======================');
    });
  }

  createLayer(name) {
    const newLayerId = newId();
    const layer = new PlateSetLayer({
      id: newLayerId,
      name: name || `Layer ${newLayerId}`,
      numCols: this.numCols,
      numRows: this.numRows,
      layout: this.layout
    });
    this.addLayer(layer);
    return layer;
  }

  get layers() {
    return this.state.layers;
  }

  addLayer(layer) {
    this.state.layers.push(layer);
    this.plates.forEach((plate) => {
      PlateSet.ensurePlateHasAllLayers(plate, this.layers, plate.numCols(), plate.numRows());
    });
  }

  // This is a partial placeholder as we migrate to plateSets having layers.
  // These are not full layers though. These are just the id and name of a layer.
  // The plate has a full layer (that contains wells)
  get visibleLayers() {
    if (this.none()) {
      return [];
    }

    const firstPlate = this.plates[0];
    return firstPlate.visibleLayers().map((layer) => {
      return {
        id: layer.id,
        name: layer.name
      };
    });
  }

  // Return x if it is an array
  // Otherwise make it into an array with one element.
  static toArray(x) {
    if (Array.isArray(x) && x.length) {
      return x;
    } else if (x === undefined) {
      return [];
    } else {
      return [x];
    }
  }
}
