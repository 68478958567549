export default class EventManager {
  constructor() {
    this.listeners = {};
  }

  // handlerObject - The object requesting registration. It will be used as 'this'
  // eventType is a string like "clicked", or "exploded"
  // handler is a function to handle the event
  // example:
  //   eventManager.register(this, 'plate/clicked', this.clickHandler, this)
  register(handlerObject, eventType, handlerFunction) {
    this.listeners[eventType] = this.listeners[eventType] || [];

    this.listeners[eventType].push({
      handlerObject: handlerObject,
      listener: handlerFunction.bind(handlerObject)
    });
  }

  unregister(handlerObject, eventType) {
    throw new Error('Not yet implemented');
  }

  trigger(eventType, data) {
    if (eventType in this.listeners) {
      this.listeners[eventType].forEach((item) => {
        console.log(`EventManager.trigger got ${eventType} and is calling`, item);
        item.listener(eventType, data);
      });
    }
  }
}
