export default class PlateInspectorView {
  constructor(scope, state, eventManager) {
    this.scope = scope;
    this.eventManager = eventManager;
    this.root = jQuery(`<div class='plateInspector window'></div>`);
    this._state = state;
    this._state.plateSets = this._state.plateSets || [];
  }

  setState(newState) {
    this._state = newState;
    this.plate = this._state.plateSets.flatMap((ps) => (ps.plates)).find((plate) => {
      return plate.isSelected();
    });
    console.log(this.plate);
    this.render();
  }

  get selectedWells() {
    const layer = this.plate.selectedLayer();
    if (!layer) {
      console.log('Warning: PlateInspectorView is checking for selectedWells, but there is no selectedLayer');
      // This is probably an error. We should't really get this far if there is no plate or layer.
      return [];
    }

    if (layer.hasSelectedWells()) {
      return layer.selectedWells();
    } else {
      return layer.wells();
    }
  }

  get(attr) {
    return this._state[attr];
  }

  render() {
    this.root.empty();
    this.root.append(jQuery(`<div class='header'>Plate Inspector</div>`));

    const inspectorBody = jQuery(`<div class='content'></div>`);
    if (this.plate) {
      const plate = this.plate;
      const needToDisplayID = this.plate.id() == this.plate.displayName() ? '' : `(${plate.id()})`;
      inspectorBody.append(jQuery(`<div>Plate <input type='text' name='plateName' class='steamlined' value='${plate.displayName()}'> ${needToDisplayID}</div>`));

      // Layers Section
      const layersSection = jQuery(`<section class='layers'></section>`);
      layersSection.append(jQuery(`
        <div class='header'>
          <div>Layers</div>
          <i class='fa-solid fa-square-plus fa-lg addLayerButton'></i>
        </div>
      `));
      plate.eachLayer((layer, layerIdx) => {
        const selected = layer.isSelected() ? 'selected' : '';
        const visibleCssClass = layer.visible() ? 'fa-eye' : 'fa-eye-slash';
        const layerDiv = jQuery(`
          <div class='layer ${selected}'>
            <div class='leftStuff'>
              <i class="fa-solid ${visibleCssClass} layerVisibility data-toggleLayer='true'"></i>
              <input type='text' value='${layer.name}'>
            </div>
            <div class='rightStuff'>
              <i class="fa-solid fa-trash deleteLayer"></i>
            </div>
          </div>
        `);
        layerDiv.data('id', layer.id);
        layerDiv.data('plateId', plate.id());
        layersSection.append(layerDiv);
      });
      inspectorBody.append(layersSection);

      // Color display option
      const colorDisplaySelectInput = jQuery(`
        <select name='colorDisplay'>
          <option value='content'>Content</option>
          <option value='amount'>Amount</option>
        </select>
      `);
      colorDisplaySelectInput.val(this.plate.getState('colorDisplay'));
      const optionsSection = jQuery(`
        <section class='options'>
          <label>Color display:</label>
        </section>
      `);
      optionsSection.append(colorDisplaySelectInput);
      inspectorBody.append(optionsSection);

      // Well section
      const rowDivs = [];

      let selectedCount = 0;
      this.selectedWells.forEach((well) => {
        selectedCount++;
        rowDivs.push(`
          <tr>
            <td><input type='text' disabled='true' value='${well.coordinateString()}'></td>
            <td><input type='text' name='label' data-wellId='${well.id()}' value='${well.label()}'></td>
            <td><input type='text' name='volumeAmount' data-wellId='${well.id()}' value='${well.amount()}'></td>
            <td><input type='text' name='volumeUnits' data-wellId='${well.id()}' value='${well.units()}'></td>
          </tr>
        `);
      });

      const wellSection = jQuery(`
        <section class='wells'>
          <div class='header'>Well Contents (${selectedCount} selected)</div>
          <table>
            <thead>
              <tr>
                <th>Well</th>
                <th>Content</th>
                <th>Amount</th>
                <th>Units</th>
              </tr>
              <tr>
                <th></th>
                <th><input type='text' name='label' data-allWells='true' placeholder='Update all'></th>
                <th><input type='text' name='volumeAmount' data-allWells='true' placeholder='Update all'></th>
                <th><input type='text' name='volumeUnits' data-allWells='true' placeholder='Update all'></th>
              </tr>
              <tr style='line-height: 4px;'>
                <td colspan='4'><hr style='border-top: 1px solid #DDD; margin-top: 2px; margin-bottom: 2px;'></td>
              </tr>
            </thead>
            <tbody>
              ${ rowDivs.join('') }
            </tbody>
          </table>
        </section>
      `);

      inspectorBody.append(wellSection);
    }

    this.root.append(inspectorBody);
    return this.root;
  }

  // TODO: Clean this up
  initEvents() {
    this.root.on('click', '.layer', (event) => {
      const layerDiv = jQuery(event.target);
      const layerId = layerDiv.closest('.layer').data('id');
      this.selectLayer(layerId);
    });

    this.root.on('click', '.layer input[type="text"]', (event) => {
      // We don't want the selected layer to change or it causes a refresh
      // and the text field loses focus
      event.stopPropagation();
    });

    this.root.on('change', '.layer input[type="text"]', (event) => {
      const element = jQuery(event.target);
      const layerId = element.closest('.layer').data('id');
      this.eventManager.trigger('layer/attributeChanged', {
        plateId: this.plate.id(),
        layerId: layerId,
        field: 'name',
        value: element.val()
      });
    });

    this.root.on('click', '.layerVisibility', (event) => {
      event.stopPropagation();
      const element = jQuery(event.target);
      const layerId = element.closest('.layer').data('id');
      const plateId = element.closest('.layer').data('plateId');
      this.eventManager.trigger('layer/toggleVisibility', {
        plateId: plateId,
        layerId: layerId
      });
    });

    this.root.on('click', '.addLayerButton', (event) => {
      event.stopPropagation();
      this.eventManager.trigger('layer/add', {
        plateId: this.plate.id()
      });
    });

    this.root.on('click', '.deleteLayer', (event) => {
      event.stopPropagation();
      const element = jQuery(event.target);
      const layerId = element.closest('.layer').data('id');
      const plateId = element.closest('.layer').data('plateId');
      this.eventManager.trigger('layer/delete', {
        plateId: plateId,
        layerId: layerId
      });
    });

    this.root.on('change', (event) => {
      const element = jQuery(event.target);
      if (element.attr('name') == 'colorDisplay') {
        this.eventManager.trigger('plate/attributeChanged', {
          plateId: this.plate.id(),
          field: 'colorDisplay',
          value: element.val()
        });
      }
      if (element.attr('data-wellId')) {
        const wellId = element.attr('data-wellId');
        const field = element.attr('name');
        const value = element.val();
        // this.plate.updateWell(wellId, field, value);
        this.eventManager.trigger('plate/wellChanged', {
          plateId: this.plate.id(),
          wellId: wellId,
          field: field,
          value: value
        });
      }
      if (element.attr('data-allWells')) {
        const field = element.attr('name');
        const value = element.val();
        this.selectedWells.forEach((well) => {
          this.eventManager.trigger('plate/wellChanged', {
            plateId: this.plate.id(),
            wellId: well.id(),
            field: field,
            value: value
          });
        });
      }

      if (element.attr('name') == 'plateName') {
        this.eventManager.trigger('plate/attributeChanged', {
          plateId: this.plate.id(),
          field: 'name',
          value: element.val()
        });
      }
    });
  }

  selectLayer(layerId) {
    console.log('000');
    this.eventManager.trigger('layer/selected', {
      plateId: this.plate.id(),
      layerId: layerId
    });
  }
}
