/* This class helps manage plate coordinates like "A2"
   It's job is to convert between different nomenclatures for defining coordinates.
   For example it can convert between "A1" and zero-based indexes: (0,0)
   It  is also intended to encapsulate row and col values so that we don't pass
   integers all over the system. Mostly because it's easy to mix up the row and column 
   in function calls like this: well.moveTo(3, 7);
*/
export default class Coordinate {
  constructor(attrs = { rowIndex: null, columnIndex: null }) {
    if (attrs.rowIndex == null || attrs.columnIndex == null) {
      throw new Error('rowIndex and columnIndex must be provided');
    }
    this._rowIndex = attrs.rowIndex;
    this._colIndex = attrs.columnIndex;
  }

  get rowIndex() {
    return this._rowIndex;
  }

  get colIndex() {
    alert('.colIndex is deprecated. Please use .columnIndex');
    debugger;
    return this._colIndex;
  }

  get columnIndex() {
    return this._colIndex;
  }

  toString() {
    return `${Coordinate.rowIndexToLetter(this.rowIndex)}${this.columnIndex + 1}`;
  }

  // If you go beyond 26, this will prefix the result with "A".
  // It will prefix with as many "A"s as the number of times you go beyond 26
  // It would be better if we did it like Excel where we do "AB", "AC", ... "BA", "BB"
  static rowIndexToLetter(rowIndex) {
    const times = Math.floor(rowIndex / 25);
    const final = rowIndex % 25;
    const letter = String.fromCharCode(65 + final);
    const prefix = 'A'.repeat(times);
    return prefix + letter;
  }

  // e.g. "A3" => new Coordinate({ rowIndex: 0, columnIndex: 2 })
  static parse(string) {
    const matches = string.toString().match(/([A-Z])(\d+)/);

    if (!matches || matches.length < 2) {
      throw new Error(`Invalid coordinate format: '${string}'`);
    }

    const rowLetter = matches[1];
    const colNumber = parseInt(matches[2]);

    // Convert A => 0, B => 1, ...
    const rowIndex = rowLetter.toUpperCase().charCodeAt(0) - 65;
    const colIndex = colNumber - 1;

    return new Coordinate({ rowIndex: rowIndex, columnIndex: colIndex });
  }
}
